import axios from 'axios';

export const filterPinkOrangeAndBlueShips = async (length, width, shipType, country, ships, setShips, deselectedShipsObjectIds, noImoShip, bunkering, sanctionedLight, sanctionedDark) => {
  let filteredShips = ships.initialShips.filter(ship => ship.staticLength >= length[0] && ship.staticLength <= length[1]).filter(ship => ship.staticWidth >= width[0] && ship.staticWidth <= width[1])

  if (shipType !== 'None') {
    filteredShips = filteredShips.filter(ship => ship.staticType === shipType)
  }
  if (country !== '') {
    filteredShips = filteredShips.filter(ship => ship.staticFlagCode === country)
  }
  if (!noImoShip) {
    filteredShips = filteredShips.filter(ship => ship.imo !== null)
  }
  if (bunkering) {
    filteredShips = filteredShips.filter(ship => ship.bunkering)
  }
  if (sanctionedLight) {
    filteredShips = filteredShips.filter(ship => ship?.sanctionedShip)
  }
  if (sanctionedDark) {
    filteredShips = filteredShips.filter(ship => ship?.sanctionedShip)
  }

  filteredShips = filteredShips.map(ship => {
    if (deselectedShipsObjectIds?.includes(ship.object_id)) {
      return { ...ship, isDeselected: true }
    } else {
      return ship;
    }
  })

  setShips({
    ...ships,
    ships: filteredShips,
    initialShips: ships.initialShips,
    shipInfoFetched: true,
    key: parseInt(Math.random() * 10000),
    noShipInfo: ships.ships
  })
}

export const filterAisShipsWhenUsingPolygon = (length, width, typeAis, country, ships, setShips, startEpochDate, polygonSliderValue, selectedShips, setSelectedShips, setPolygonShip, noImoAis, sanctionedAis) => {
  const timestampToFilterStart = startEpochDate + (polygonSliderValue * 15 * 60)
  const timestampToFilterEnd = timestampToFilterStart + (15 * 60)
  let filteredShips = ships.initialShips
    .filter(ship => ship.staticLength >= length[0] && ship.staticLength <= length[1])
    .filter(ship => ship.staticWidth >= width[0] && ship.staticWidth <= width[1])
    .filter(ship => ((ship.timestamp < timestampToFilterEnd) && (ship.timestamp > timestampToFilterStart)))

  if (typeAis !== 'None') {
    filteredShips = filteredShips.filter(ship => ship.staticType === typeAis)
  }
  if (country !== '') {
    filteredShips = filteredShips.filter(ship => ship.staticFlagCode === country)
  }

  if (!noImoAis) {
    filteredShips = filteredShips.filter(ship => ship.imo !== null)
  }

  if (sanctionedAis) {
    filteredShips = filteredShips.filter(ship => ship?.sanctionedShip)
  }

  filteredShips = filterDuplicateShips(filteredShips)

  filteredShips.forEach(ship => {
    selectedShips.forEach(selectedShip => {
      if (selectedShip.shipCategory === "ais") {
        if (selectedShip.initialData.properties.synmax_ship_id === ship.synmax_ship_id) {
          const updatedSelectedShipArr = selectedShips.map(selectedShip => {
            if (selectedShip.initialData.properties.synmax_ship_id === ship.synmax_ship_id) {
              return {
                ...selectedShip,
                initialData: {
                  ...selectedShip.initialData,
                  geometry: { type: 'Point', coordinates: [ship.longitude, ship.latitude] },
                  properties: {
                    ...selectedShip.initialData.properties,
                    timestamp: ship.timestamp
                  }
                }
              }
            } else {
              return selectedShip;
            }
          })
          setSelectedShips(updatedSelectedShipArr)

        }
      } else {
        if (selectedShip.initialData.properties.attribution === ship.synmax_ship_id) {
          const updatedSelectedShipArr = selectedShips.map(selectedShip => {
            if (selectedShip.initialData.properties.attribution === ship.synmax_ship_id) {
              return {
                ...selectedShip,
                initialData: {
                  ...selectedShip.initialData,
                  geometry: { type: 'Point', coordinates: [ship.longitude, ship.latitude] },
                  properties: {
                    ...selectedShip.initialData.properties,
                    acquired: ship.timestamp
                  }
                }
              }
            } else {
              return selectedShip;
            }
          })
          setSelectedShips(updatedSelectedShipArr)
        }
      }
    })
  })

  if (filteredShips?.length > 0) {
    setPolygonShip(filteredShips);
  }
  setShips({
    ...ships,
    ships: filteredShips,
    initialShips: ships.initialShips,
    key: parseInt(Math.random() * 10000)
  })

  return;
}

export const filterPurpleShipsWhenUsingPolygon = (typeAis, country, ships, setShips, startEpochDate, polygonSliderValue, selectedShips) => {
  const timestampToFilterStart = startEpochDate + (polygonSliderValue * 15 * 60)
  const timestampToFilterEnd = timestampToFilterStart + (15 * 60)

  let filteredShips = ships?.initialShips
    .filter(ship => ((ship.timestamp < timestampToFilterEnd) && (ship.timestamp > timestampToFilterStart)))

  if (typeAis !== 'None') {
    filteredShips = filteredShips.filter(ship => ship.staticType === typeAis)
  }
  if (country !== '') {
    filteredShips = filteredShips.filter(ship => ship.staticFlagCode === country)
  }
  filteredShips = filterDuplicateShips(filteredShips)

  setShips({
    ...ships,
    ships: filteredShips,
    initialShips: ships.initialShips,
    key: parseInt(Math.random() * 10000)
  })

  return;
}

export const filterThroughDataManager = (length, width, typeAis, country, ships, setShips, noImoAis, sanctionedAis) => {
  console.log('Filtering through data manager')
  let filteredShips = ships.initialShips.filter(ship => ship.length >= length[0] && ship.length <= length[1]).filter(ship => ship.width >= width[0] && ship.width <= width[1])
  if (!noImoAis) {
    filteredShips = filteredShips.filter(ship => ship.imo !== null)
  }
  if (typeAis !== 'None') {
    console.log(filteredShips, 'before type filter')
    filteredShips = filteredShips.filter(ship => ship.ship_type === typeAis)
    console.log(filteredShips, 'after type filter')
  }
  if (country !== '') {
    filteredShips = filteredShips.filter(ship => ship.flag_short_code === country)
    console.log(filteredShips.filter(ship => ship.ship_type), 'after country filter')
  }

  if (sanctionedAis) {
    filteredShips = filteredShips.filter(ship => ship?.sanctionedShip)
  }

  setShips({
    ...ships,
    ships: filteredShips,
    initialShips: ships.initialShips,
    key: parseInt(Math.random() * 10000)
  })

  return;
}

export const filterRedShips = (length, headingUnknown, typeUnknown, movingUnknown, ships, setShips, deselectedShipsObjectIds, bunkering) => {
  let filteredShips = ships.initialShips.filter(ship => ship.length >= length[0] && ship.length <= length[1]).filter(ship => ship.heading >= headingUnknown[0] && ship.heading <= headingUnknown[1])

  if (typeUnknown !== 'None') {
    if (typeUnknown === 'cargo') {
      filteredShips = filteredShips.filter(ship => ship.ship_type === 'Cargo')
    }
    if (typeUnknown === 'tanker') {
      filteredShips = filteredShips.filter(ship => ship.ship_type === 'Tanker')
    }
    if (typeUnknown === 'other') {
      filteredShips = filteredShips.filter(ship => (ship.ship_type !== 'Cargo' && ship.ship_type !== 'Tanker'))
    }
  }

  if (movingUnknown !== 'None') {
    if (movingUnknown === 'yes') {
      filteredShips = filteredShips.filter(ship => ship.moving)
    }
    if (movingUnknown === 'no') {
      filteredShips = filteredShips.filter(ship => !ship.moving)
    }
  }

  if (bunkering) {
    filteredShips = filteredShips.filter(ship => ship.bunkering)
  }
  filteredShips = filteredShips.map(ship => {
    if (deselectedShipsObjectIds?.includes(ship.object_id)) {
      return { ...ship, isDeselected: true }
    } else {
      return ship;
    }
  })
  setShips({
    ships: filteredShips,
    initialShips: ships.initialShips,
    key: parseInt(Math.random() * 10000)
  })

}


export const filterDuplicateShips = (oldArr) => {
  const newArr = []
  oldArr.forEach((ship, i) => {
    const index = newArr.findIndex(newArrShip => newArrShip.synmax_ship_id === ship.synmax_ship_id)

    if (index === -1) {
      newArr.push(ship)
    } else {
      if (newArr[index].synmax_ship_id === ship.synmax_ship_id && newArr[index].timestamp < ship.timestamp) {
        newArr[index] = oldArr[i]
      }
    }
  })
  return newArr;
}