import React, {
  useEffect,
  useRef,
} from "react";
import {
  withLeaflet,
  Polyline,
} from "react-leaflet";
import L from "leaflet";

export const PolylineDecorator = withLeaflet((props) => {
  const polyRef = useRef();
  useEffect(() => {
    const polyline = polyRef.current.leafletElement; //get native Leaflet polyline
    const { map } = polyRef.current.props.leaflet; //get native Leaflet map

    var test = L.polylineDecorator(polyline, {
      leaflet_id: 9999,
      patterns: [
        {
          offset: 0,
          repeat: 50,
          symbol: L.Symbol.arrowHead({
            pixelSize: 8,
            polygon: false,
            pathOptions: {
              stroke: true,
              color: "white",
              opacity: 1,
              interactive: false,
              smoothFactor: 1,
            },
          }),
        },
      ],
    });

    test.addTo(map);
  }, []);
  return <Polyline ref={polyRef} {...props} />;
});

export const formatPathData = (pathData) => {
  const updatedPathData = [];
  pathData?.forEach(({ latitude: lat, longitude: lon, timestamp: t }) =>
    updatedPathData.push({ lat, lon, t })
  );
  return updatedPathData;
};
