import React, { useEffect, useState, useRef } from 'react';
import { StylesProvider } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Drawer, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import moment from 'moment'

import Navbar from './Navbar/Navbar';
import LeafletMap from './LeafletMap/LeafletMap';
import PolygonSlider from './LeafletMap/PolygonSlider';
import DrawerData from './DrawerData/DrawerData';
import { DataManager } from './DataManager/DataManager'
import { DropPin } from './components/DropPin';
import PathSlider from "./components/PathSlider/PathSlider";
import { TerracottaImageSlider } from './components/TerracottaImageSlider'
import './theia-p3.scss';
import FuturePathPredictionSlider from './components/FuturePathPredictionSlider';
import PathPredictionLoaderUI from './components/PathPredictionLoaderUI';
import axios from 'axios';
import { filterDuplicateShips, filterThroughDataManager, filterPinkOrangeAndBlueShips, filterAisShipsWhenUsingPolygon, filterRedShips, filterPurpleShipsWhenUsingPolygon } from '../Theia-Phase3/LeafletMap/AllRenderedShips/utils';
import { ShipData } from './LeafletMap/AllRenderedShips/ShipData';
import CollapsibleTable from './LeafletMap/AllRenderedShips/CollapsibleTable';
import Welcome from './components/Welcome';
import Updates from './components/Updates';
import { frontendAPIURL } from './utils';
import SatelliteCoverageSlider from './components/SatelliteCoverageSlider';
import { bunkeringDataAdam } from '../../geojsons/bunkering';
import CurrentLocationLoader from './LeafletMap/SatelliteCoverageSpoofing/CurrentLocationLoader';


export const TheiaContext = React.createContext();

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#23283D',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  typography: {
    htmlFontSize: 10,
  },
});

const Theia = ({ logout, userData }) => {
  const pathSliderRef = useRef(null);
  const [date, setDate] = useState(new Date());
  const [selectedPolygons, setSelectedPolygons] = useState([])
  const [selectedPolylines, setSelectedPolylines] = useState([])
  const [createdCirclesObjs, setCreatedCirclesObjs] = useState([])
  const [deletePolygonIds, setDeletePolygonIds] = useState([])
  const [dropdownPolygonId, setDropdownPolygonId] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showSpinner, setShowSpinner] = useState(false)
  const [polygonShip, setPolygonShip] = useState([])
  // we want to keep a copy of original ships as initialShips, to filter out the ships
  const [aisShips, setAisShips] = useState({
    ships: [],
    initialShips: [],
    key: 0
  })
  const [initialDate, setInitialDate] = useState('')
  const [selectedShips, setSelectedShips] = useState([])
  const [isOpenDrawerData, setOpenDrawerData] = useState(true);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  // We use this as refresh key for path updates
  const [shipViewToggle, setShipViewToggle] = useState(false)
  const [viewAnalyzePath, setViewAnalyzePath] = useState(false)
  const [colorArr, setColorArr] = useState([])
  const [mapCenter, setMapCenter] = useState({ lat: 18, lng: 18 })
  // const [mapCenter, setMapCenter] = useState({ lat: 24.42, lng: 120.33 })
  const [droppedPins, setDroppedPins] = useState([])
  const [viewPathSlider, setViewPathSlider] = useState(false); /* attention */
  const [darkShipDetections, setDarkShipDetections] = useState({
    ships: [],
    key: 0
  })
  const [blueShipDetections, setBlueShipDetections] = useState({
    ships: [],
    key: 0
  })
  const [spoofingDetections, setSpoofingDetections] = useState({
    ships: [],
    key: 0
  })
  const [similarDetections, setSimilarDetections] = useState([])
  const [orangeShipDetections, setOrangeShipDetections] = useState({
    ships: [],
    key: 0
  })
  const [purpleShips, setPurpleShips] = useState({
    ships: [],
    initialShips: [],
    key: 0
  })
  const [darkShipDetectionsInPolygon, setDarkShipDetectionsInPolygon] = useState({
    ships: [],
    key: 0
  })
  const [blueShipDetectionsInPolygon, setBlueShipDetectionsInPolygon] = useState({
    ships: [],
    key: 0
  })
  const [orangeShipDetectionsInPolygon, setOrangeShipDetectionsInPolygon] = useState({
    ships: [],
    key: 0
  })
  const [filters, setFilters] = useState({
    ais: false,
    light: true,
    dark: true,
    unknown: true,
    // sanctionedShips: false,
    // spoofing -> optical spoofing
    spoofing: true,
    decoupleBunkering: true,
    footprintSpoofing: true,
    similarShips: true,
    noImoAis: false,
    noImoLight: false,
    noImoDark: false,
    noImoUnknown: false,
    noImoSpoofing: true,
    aisSpoofing: true,
    opticalSpoofing: true,
    footprintSpoofing: true,
    noImoFootprint: true,
    bunkeringLight: false,
    sanctionedLight: false,
    sanctionedAis: false,
    sanctionedDark: false,
    bunkeringAis: false,
    bunkeringOrange: false,
    bunkeringUnknown: false,
    lengthAis: [0, 500],
    lengthDark: [0, 500],
    lengthLight: [0, 500],
    lengthSpoofing: [0, 500],
    lengthFootprint: [0, 500],
    lengthUnknown: [100, 500],
    widthAis: [0, 100],
    widthDark: [0, 100],
    widthLight: [0, 100],
    widthSpoofing: [0, 100],
    widthFootprint: [0, 100],
    headingUnknown: [0, 360],
    typeAis: 'None',
    typeUnknown: 'None',
    movingUnknown: 'None',
    typeDark: 'None',
    typeLight: 'None',
    typeSpoofing: 'None',
    typeFootprint: 'None',
    countryAis: '',
    countryDark: '',
    countryLight: '',
    countryUnknown: '',
    countrySpoofing: '',
    countryFootprint: '',
  }); /* attention */

  const [switches, setSwitches] = useState({
    territories: false,
    ports: false,
    infrastructure: false,
    submarineCablesVisible: false,
    heatmap: false,
    coverage: false,
    showCluster: true,
  });
  const [allSanctionedShips, setAllSanctionedShips] = useState([]);

  const [sanctionedShipsPositionData, setSanctionedShipsPositionData] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(3)
  const [dragValueForRerender, setDragValueForRerender] = useState(false);
  const [dragEndValueForRerender, setDragEndValueForRerender] = useState(false);
  const [tileLayerUrl, setTileLayerUrl] = useState(null)
  const [viewSatelliteImage, setViewSatelliteImage] = useState(false)
  const [pathInterpolationData, setPathInterpolationData] = useState(null)
  const [viewPathInterpolation, setViewPathInterpolation] = useState(false)
  const [refreshViewClicked, setRefreshViewClicked] = useState(0)
  const [viewDetectionShipClicked, setViewDetectionShipClicked] = useState(0)
  const [progressLoading, setProgressLoading] = useState(false)
  const [tileBlueValue, setTileBlueValue] = useState([5654, 6084]);
  const [tileGreenValue, setTileGreenValue] = useState([3484, 3848]);
  const [tileRedValue, setTileRedValue] = useState([2104, 2482]);
  const [isFiltersChanged, setIsFiltersChanged] = useState(false)
  const [haveShipInfo, setHaveShipInfo] = useState(false)
  const [filterAisData, setFilterAisData] = useState(true)
  const [startEpochDate, setStartEpochDate] = useState()
  const [polygonSliderValue, setPolygonSliderValue] = useState(0);
  const [polygonSliderAisShipsKey, setPolygonAisSliderShipsKey] = useState(0)
  const [filtersDetectionsKey, setFiltersDetectionsKey] = useState(0)
  const [isLiveAisView, setIsLiveAisView] = useState(true)
  const [deselectedShipsObjectIds, setDeselectedShipsObjectIds] = useState([])
  const [redBand, setRedBands] = useState([])
  const [greenBand, setGreenBand] = useState([])
  const [blueBand, setBlueBand] = useState([])
  // we use this state value to re-render icons everytime we add/remove attribution
  const [isAttributionDataUpdated, setIsAttributionDataUpdated] = useState(false)
  const [mapTheme, setMapTheme] = useState('dark')
  const [pathPredictionData, setPathPredictionData] = useState(null)
  const [futurePathObj, setFuturePathObj] = useState(null)
  const [pathPredictionStatusUrl, setPathPredictionStatusUrl] = useState(null)
  const [purpleShipVisible, setPurpleShipVisible] = useState(true);
  const [shipDataDialog, setShipDataDialog] = useState(false)
  const [selectShipWaiting, setSelectShipWaiting] = useState(false);
  const [intervalId, setIntervalId] = useState(null)
  const [resultUrl, setResultUrl] = useState(null)
  const [progressValue, setProgressValue] = useState(0)
  const [progressStatus, setProgressStatus] = useState('')
  const [zIndex, setZindex] = useState({
    dropPin: false,
    pathSlider: false,
    attribution: false,
    satelliteImage: false,
    extendedPath: false,
    polygonSlider: false,
    polygonDataTable: false,
    futurePathModal: false,
    futurePathSlider: false,
    spoofingTimelineSlider: false,
    portsOfCallSlider: false,
  });
  const [footprintShips, setFootprintShips] = useState({ ships: [], key: 0 })
  const [AisSpoofingShips, setAisSpoofingShips] = useState({ ships: [], key: 0 })
  const [satelliteData, setSatelliteData] = useState({ geojson: null, key: 0 })
  const [satelliteTimelineData, setSatelliteTimelineData] = useState({ geojson: null, key: 0 })
  const [currentLocationData, setCurrentLocationData] = useState({ geojson: null, key: 0 })
  const [spoofingTimelineShips, setSpoofingTimelineShips] = useState([])
  const [spoofingHeatmapVisible, setSpoofingHeatmapVisible] = useState(false)
  const [spoofingHeatmapTimelineVisible, setSpoofingHeatmapTimelineVisible] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(false)
  const [uploadedGeojsons, setUploadedGejson] = useState([])
  const [uploadedCSVs, setUploadedCSVs] = useState([])
  const [bunkeringData, setBunkeringData] = useState({ events: [], key: 0 })
  const [aisBunkeringData, setAisBunkeringData] = useState({ events: [], key: 0 })

  const [showPathPredictionDialog, setShowPathPredictionDialog] = useState(false)

  const mapRef = useRef(null);

  const controller = new AbortController();

  const [isAbortController, setIsAbortController] = useState(new AbortController());

  useEffect(() => {
    console.log(bunkeringDataAdam, ':bunkeringdataadam')
  }, [])

  useEffect(() => {
    if (moment(date.$d).format('YYYY-MM-DD') !== moment(new Date()).format('YYYY-MM-DD')) {
      setIsLiveAisView(false)
    } else {
      setIsLiveAisView(true)
    }
  }, [date])

  useEffect(() => {
    if (selectedShips.length && selectedShips?.observations) {
      const initialDataShip = selectedShips[0].initialData
      console.log(initialDataShip, 'initialDataShip')
    }
  }, [JSON.stringify(selectedShips[0]?.initialData), selectedShips?.observations])

  useEffect(() => {
    // Clear ships everytime we switch polygon, can be better optimized in the future
    setAisShips({
      ships: [],
      initialShips: [],
      key: parseInt(Math.random() * 10000)
    })
  }, [dropdownPolygonId])

  useEffect(() => {
    console.log(selectedShips, 'rerender selectedShips')
  }, [selectedShips])

  const haveShipsSelected = selectedShips.length !== 0;

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const { lengthAis, widthAis, typeAis, countryAis, lengthDark, widthDark, typeDark, countryDark, lengthLight, widthLight, typeLight, countryLight, lengthUnknown, headingUnknown, typeUnknown, movingUnknown } = filters;

  const getDetectedData = async (points) => {
    const token = localStorage.getItem('token')

    const dateString = moment(date.$d).format('YYYY-MM-DD')
    setShowSpinner(true)
    const res = await axios.post(`${frontendAPIURL}/objects_in_area`, {
      "id": "frontend", "start": `${dateString} 00:00:00`, "end": `${dateString} 23:59:59`, "area": { "type": "Polygon", "coordinates": [points] }
    }
      , {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }).catch(() => {
        setShowSpinner(false)
      })

    const allInitialShips = res.data
    const allShipIds = allInitialShips.map(ship => ship.attribution).filter(attribution => attribution !== 'None')
    const shipInfoRes = await axios.post(`${frontendAPIURL}/ship_info`,
      { "id": "frontend", "shipids": allShipIds }
      , {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }).catch(() => {
        setShowSpinner(false)
      })
    setShowSpinner(false)
    let uniqueShipMmsis = []
    let allUniqueShips = []

    shipInfoRes.data.static.forEach(ship => {
      if (uniqueShipMmsis.includes(ship.mmsi)) {
        return;
      } else {
        uniqueShipMmsis.push(ship.mmsi)
        const newShipObject = { ...allInitialShips.filter(orangeShip => orangeShip.attribution === ship.synmax_ship_id)[0], staticLength: ship.length, staticWidth: ship.width, staticType: ship.ship_type, staticFlagCode: ship.flag, name: ship?.name, destination: ship?.destination }
        allUniqueShips.push(newShipObject)
      }
    })
    const updatedOrangeShips = { initialShips: allUniqueShips.filter(detection => (detection.dark && detection.attribution !== 'None')) }
    filterPinkOrangeAndBlueShips(lengthDark, widthDark, typeDark, countryDark, updatedOrangeShips, setOrangeShipDetectionsInPolygon, deselectedShipsObjectIds)

    const updatedBlueShips = { initialShips: allUniqueShips.filter(detection => (!detection.dark && detection.attribution !== 'None')) }
    filterPinkOrangeAndBlueShips(lengthLight, widthLight, typeLight, countryLight, updatedBlueShips, setBlueShipDetectionsInPolygon, deselectedShipsObjectIds)

    const updatedRedShips = { initialShips: allInitialShips.filter(detection => (!detection.dark && detection.attribution === 'None')), ships: res.data.filter(detection => (!detection.dark && detection.attribution === 'None')), key: parseInt(Math.random() * 10000) }

    filterRedShips(lengthUnknown, headingUnknown, typeUnknown, movingUnknown, updatedRedShips, setDarkShipDetectionsInPolygon, deselectedShipsObjectIds)
  }

  const getAisInPolygonArea = async (onlyLargeShips, points) => {

    if (filterAisData) {
      setFilterAisData(false)
    }
    const token = localStorage.getItem('token')
    setShowSpinner(true)
    const selectedPolygon = selectedPolygons.filter(polygon => polygon.id === dropdownPolygonId)[0]
    console.log('ais in area ca;;')
    const res = await axios.post(`${frontendAPIURL}/ais_in_area`,
      {
        id: "frontend",
        start: `${moment(startDate).format("YYYY-MM-DD")} 00:00:00`,
        end: `${moment(endDate).format("YYYY-MM-DD")} 23:59:59`,
        area: { type: "Polygon", coordinates: [points] },
      },
      {
        signal: controller.signal,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }).catch(() => {
        setShowSpinner(false)
      })
    console.log(res.data, 'res.data')

    const allInitiallyFetchedShips = res.data
    let uniqueShipIds = []
    let purpleShipDetails = []
    allInitiallyFetchedShips.forEach(ship => {
      if (uniqueShipIds.includes(ship.synmax_ship_id)) {
        return;
      } else {
        uniqueShipIds.push(ship.synmax_ship_id)
        purpleShipDetails.push(ship)
      }
    })

    const shipInfoRes = await axios.post(`${frontendAPIURL}/ship_info`,
      { "id": "frontend", "shipids": uniqueShipIds }
      , {
        headers: {
          Authorization: "Bearer " + token,
        },
      }).catch(() => {
        setShowSpinner(false)
      })
    setShowSpinner(false)
    let uniqueShipMmsis = []
    let allUniqueShips = {}
    let polygonPurpleShips = []
    let initialPurpleShips = []

    shipInfoRes.data.static.forEach(ship => {
      if (uniqueShipMmsis.includes(ship.mmsi)) {
        return;
      } else {
        uniqueShipMmsis.push(ship.mmsi)
        allUniqueShips[ship.synmax_ship_id] = ship
      }
    })
    uniqueShipIds.forEach((e) => {
      if (allUniqueShips[e]) {
        return;
      } else {
        purpleShipDetails.map((id) => {
          if (id.synmax_ship_id === e) {
            polygonPurpleShips.push(id);
          }
        })

      }
    })

    allInitiallyFetchedShips.forEach((ship) => {
      polygonPurpleShips.map((purple) => {
        if (ship.synmax_ship_id === purple.synmax_ship_id) {
          initialPurpleShips.push(ship)
        }
      })
    })

    let allShipsForPolygon = []
    let allPurpleShips = []

    allInitiallyFetchedShips.forEach(ship => {
      if (allUniqueShips[ship.synmax_ship_id]) {
        const { length, width, ship_type, flag, name, destination, imo } =
          allUniqueShips[ship.synmax_ship_id];

        const isSanctioned = sanctionedShipsPositionData?.some(
          sanctionedShip => sanctionedShip.synmax_ship_id === ship.synmax_ship_id
        );

        allShipsForPolygon.push({
          ...ship,
          staticLength: length,
          staticWidth: width,
          staticType: ship_type,
          staticFlagCode: flag,
          name: name,
          destination: destination ? destination : "-",
          imo,
          sanctionedShip: isSanctioned
        });
      }
    })

    const timestampToFilterStart = startEpochDate
    const timestampToFilterEnd = timestampToFilterStart + (15 * 60)
    const filteredShips = allShipsForPolygon.filter(ship => ((ship.timestamp <= timestampToFilterEnd) && (ship.timestamp > timestampToFilterStart)))
    const purpleShipFilters = initialPurpleShips.filter(ship => ((ship.timestamp <= timestampToFilterEnd) && (ship.timestamp > timestampToFilterStart)))
    console.log(timestampToFilterEnd, 'timestampToFilterEnd')

    const polygonShipsKey = parseInt(Math.random() * 10000)
    setAisShips(aisShips => ({
      ...aisShips,
      ships: filterDuplicateShips(filteredShips),
      initialShips: allShipsForPolygon,
      key: polygonShipsKey,
    }))

    setPurpleShips(purpleships => ({
      ...purpleships,
      ships: filterDuplicateShips(purpleShipFilters),
      initialShips: initialPurpleShips,
      key: parseInt(Math.random() * 10000)
    }))

    setPolygonAisSliderShipsKey(polygonShipsKey)
    setIsFiltersChanged(true)
  }

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message="Note archived"
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Ship Details Not found
          </Alert>
        </Snackbar>
        <div className={isOpenDrawerData ? "theia-p3" : "theia-p3  shipsSelectedActive"}>
          <TheiaContext.Provider value={{
            mapTheme,
            setMapTheme,
            tileLayerUrl,
            setTileLayerUrl,
            viewSatelliteImage,
            setViewSatelliteImage,
            selectedPolylines,
            setSelectedPolylines,
            isOpenDrawerData,
            viewPathInterpolation,
            setViewPathInterpolation,
            pathInterpolationData,
            setPathInterpolationData,
            setZoomLevel,
            dragValueForRerender,
            setDragValueForRerender,
            zoomLevel,
            haveShipsSelected,
            setDroppedPins,
            filters,
            blueShipDetections,
            orangeShipDetections,
            setOrangeShipDetections,
            setBlueShipDetections,
            darkShipDetections,
            setDarkShipDetections,
            setFilters,
            switches,
            setSwitches,
            mapCenter,
            droppedPins,
            setMapCenter,
            colorArr,
            setColorArr,
            selectedShips,
            createdCirclesObjs,
            setCreatedCirclesObjs,
            viewAnalyzePath,
            setViewAnalyzePath,
            setSelectedShips,
            setIsSnackbarOpen,
            shipViewToggle,
            setShipViewToggle,
            showSpinner,
            setShowSpinner,
            startDate,
            initialDate,
            setInitialDate,
            aisShips,
            dropdownPolygonId,
            setAisShips,
            setStartDate,
            endDate,
            setEndDate,
            selectedPolygons,
            setSelectedPolygons,
            setDropdownPolygonId,
            deletePolygonIds,
            viewPathSlider,
            pathSliderRef,
            setViewPathSlider,
            setDeletePolygonIds,
            refreshViewClicked,
            setRefreshViewClicked,
            viewDetectionShipClicked,
            setViewDetectionShipClicked,
            tileBlueValue,
            setTileBlueValue,
            tileGreenValue,
            setTileGreenValue,
            tileRedValue,
            setTileRedValue,
            isFiltersChanged,
            setHaveShipInfo,
            setIsFiltersChanged,
            filterAisData,
            setFilterAisData,
            startEpochDate,
            setStartEpochDate,
            polygonSliderValue,
            setPolygonSliderValue,
            mapRef,
            polygonSliderAisShipsKey,
            setPolygonAisSliderShipsKey,
            filtersDetectionsKey,
            setFiltersDetectionsKey,
            isLiveAisView,
            deselectedShipsObjectIds,
            setDeselectedShipsObjectIds,
            setRedBands,
            redBand,
            blueBand,
            setBlueBand,
            setGreenBand,
            greenBand,
            isAttributionDataUpdated,
            setIsAttributionDataUpdated,
            pathPredictionData,
            setPathPredictionData,
            futurePathObj,
            setFuturePathObj,
            pathPredictionStatusUrl,
            setPathPredictionStatusUrl,
            progressStatus,
            setProgressStatus,
            setPolygonShip,
            polygonShip,
            purpleShips,
            setPurpleShips,
            purpleShipVisible,
            setPurpleShipVisible,
            dragEndValueForRerender,
            setDragEndValueForRerender,
            getDetectedData,
            date,
            setShipDataDialog,
            shipDataDialog,
            getAisInPolygonArea,
            selectShipWaiting,
            setSelectShipWaiting,
            darkShipDetectionsInPolygon,
            setDarkShipDetectionsInPolygon,
            blueShipDetectionsInPolygon,
            setBlueShipDetectionsInPolygon,
            orangeShipDetectionsInPolygon,
            setOrangeShipDetectionsInPolygon,
            intervalId,
            setIntervalId,
            progressValue,
            setProgressValue,
            resultUrl,
            setResultUrl,
            zIndex,
            setZindex,
            userData,
            spoofingDetections,
            setSpoofingDetections,
            similarDetections,
            setSimilarDetections,
            footprintShips,
            setFootprintShips,
            satelliteData,
            setSatelliteData,
            spoofingTimelineShips,
            setSpoofingTimelineShips,
            spoofingHeatmapVisible,
            setSpoofingHeatmapVisible,
            spoofingHeatmapTimelineVisible,
            setSpoofingHeatmapTimelineVisible,
            currentLocation,
            setCurrentLocation,
            uploadedGeojsons,
            setUploadedGejson,
            uploadedCSVs,
            setUploadedCSVs,
            bunkeringData,
            setBunkeringData,
            aisBunkeringData,
            setAisBunkeringData,
            showPathPredictionDialog,
            setShowPathPredictionDialog,
            AisSpoofingShips,
            setAisSpoofingShips,
            currentLocationData,
            setCurrentLocationData,
            progressLoading,
            setProgressLoading,
            isAbortController,
            setIsAbortController,
            satelliteTimelineData,
            setSatelliteTimelineData,
            allSanctionedShips,
            setAllSanctionedShips,
            sanctionedShipsPositionData,
            setSanctionedShipsPositionData
          }}>
            <Navbar logout={logout} date={date} setDate={setDate} />
            <DataManager />
            <LeafletMap date={date} />
            <Welcome />
            <Updates />

            <div className='handle-Draggable'>
              <PolygonSlider />
            </div>

            <div className='handle-Draggable'>
              {viewPathSlider && (
                <PathSlider
                  selectedShips={selectedShips}
                  pathSliderRef={pathSliderRef}
                  viewPathSlider={viewPathSlider}
                />
              )}
            </div>

            {haveShipsSelected && (
              <Drawer
                variant="persistent"
                className='bottomDrawerBox'
                anchor={'bottom'}
                open={haveShipsSelected}
                transitionDuration={500}
              >
                <DrawerData isOpenDrawerData={isOpenDrawerData} setOpenDrawerData={setOpenDrawerData} />
              </Drawer>
            )}

            <DropPin setDroppedPins={setDroppedPins} />
            {pathPredictionData && <FuturePathPredictionSlider />}
            <CollapsibleTable shipDataDialog={shipDataDialog} setShipDataDialog={setShipDataDialog} />

            {pathPredictionStatusUrl && <PathPredictionLoaderUI />}
            {progressLoading && <CurrentLocationLoader />}
            {spoofingTimelineShips.length > 0 && <SatelliteCoverageSlider setSpoofingTimelineShips={setSpoofingTimelineShips} spoofingTimelineShips={spoofingTimelineShips} setSatelliteTimelineData={setSatelliteTimelineData} />}
          </TheiaContext.Provider>
        </div>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default Theia
