import React, { useState, useCallback, memo, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import cn from "classnames";
import debounce from "lodash.debounce";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Slider from "@mui/material/Slider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ReactSVG } from "react-svg";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styles from "./dataManager.module.css";
import { countryCodes } from './countryCodes'
import CloseIcon from '@mui/icons-material/Close';
import { TheiaContext } from "../Theia";
import { CheckBox } from "@mui/icons-material";

const aspireTypes = [
  "None",
  "Special Craft",
  "Sailing Vessel",
  "Wing In Ground",
  "High-Speed Craft",
  "Search and Rescue",
  "Pleasure Craft",
  "Reserved",
  "Cargo",
  "Tug",
  "Passenger",
  "Other",
  "Sailing",
  "Tanker",
  "Fishing Vessel",
  "Fishing",
  "Passenger Ship"
]

const FilterDataManager = ({
  isFilterOpen,
  iconShip,
  filterName,
  toggleFilter,
  onChangeFilter,
  dataCheckbox,
  showDropdown,
  dataSlider,
  widthSlider,
  dataSelect,
  countryPicker,
  typePicker,
  setPurpleShipVisible,
  purpleShipVisible,
  noImoShips,
  bunkering,
  footprintSpoofing,
  opticalSpoofing,
  aisSpoofing,
  sanctionedLight,
  sanctionedDark,
  sanctionedAis
}) => {
  const { filters } = useContext(TheiaContext);
  const [valueSlider, setValueSlider] = useState(dataSlider ? dataSlider.value : null);
  const [widthValueSlider, setWidthValueSlider] = useState(widthSlider ? widthSlider.value : null);
  const [headingValueSlider, setHeadingValueSlider] = useState(filters.headingUnknown)
  const [selectedFlag, setSelectedFlag] = useState(null)
  const [unknownType, setUnknownType] = useState("None");
  const [unknownIsMoving, setUnknownIsMoving] = useState("None");

  const debounceChangeFilter = useCallback(
    debounce((data) => {
      onChangeFilter(data);
    }, 200),
    []
  );

  const onChangeSlider = (event, newValue) => {
    setValueSlider(newValue);
    debounceChangeFilter({ [dataSlider.name]: newValue });
  };

  const onChangeWidthSlider = (event, newValue) => {
    setWidthValueSlider(newValue);
    debounceChangeFilter({ [widthSlider.name]: newValue });
  };

  const onChangeHeadingSlider = (event, newValue) => {
    setHeadingValueSlider(newValue);
    debounceChangeFilter({ ['headingUnknown']: newValue });
  };

  const onChangeSelect = (event) => {
    dataSelect.onChange({ [dataSelect.name]: event.target.value });
  };

  const onDarkShipTypeChange = (e) => {
    setUnknownType(e.target.value)
    debounceChangeFilter({ ['typeUnknown']: e.target.value });
  }

  const onDarkShipIsMovingChange = (e) => {
    setUnknownIsMoving(e.target.value)
    debounceChangeFilter({ ['movingUnknown']: e.target.value });
  }

  return (
    <>
      <div className={styles.filterRow}>
        <div className={styles.filterName}>
          <Checkbox
            size="small"
            id={filterName}
            checked={dataCheckbox.value}
            onChange={(event) => onChangeFilter({ [filterName]: event.target.checked })}
          />
          <label htmlFor={filterName} className={cn(styles.filterLabel)}>
            <span style={{ color: dataCheckbox.colorLabel }}>{dataCheckbox.label}</span>
            <i>{dataCheckbox.subLabel}</i>
          </label>
        </div>
        <div>
          <IconButton
            color="primary"
            onClick={() => {
              onChangeFilter({ [filterName]: !dataCheckbox.value });
            }}
          >
            <ReactSVG className={cn(styles.iconShip, !dataCheckbox.value && styles.iconUnactive)} src={iconShip} />
          </IconButton>
      {showDropdown && (
        <IconButton
            color="primary"
            onClick={() => {
              toggleFilter(filterName);
            }}
          >
            <FilterAltIcon fontSize="small" />
          </IconButton>
      )}    
        </div>
      </div>

      {isFilterOpen === filterName && (
        <div>
          {filterName === "ais" &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                id={'purple'}
                checked={purpleShipVisible}
                onChange={(event) => setPurpleShipVisible(event.target.checked)}
                label="Label"
              />
              <div>
                <p style={{ fontWeight: "bolder", fontSize: '12px' }}>Ship without info</p>
              </div>
            </div>
          }
          {noImoShips !== undefined && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                id={'purple'}
                checked={noImoShips.value}
                onChange={(e) => onChangeFilter({ [noImoShips.name]: !noImoShips.value })}
                label="Label"
              />
              <div>
                <p style={{ fontWeight: "bolder", fontSize: '12px' }}>No IMO Ships</p>
              </div>
            </div>
          )}
          {footprintSpoofing !== undefined && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                id={'purple'}
                checked={footprintSpoofing.value}
                onChange={(e) => onChangeFilter({ [footprintSpoofing.name]: !footprintSpoofing.value })}
                label="Label"
              />
              <div>
                <p style={{ fontWeight: "bolder", fontSize: '12px' }}>Show Footprint Only</p>
              </div>
            </div>
          )}
          {opticalSpoofing !== undefined && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                id={'purple'}
                checked={opticalSpoofing.value}
                onChange={(e) => onChangeFilter({ [opticalSpoofing.name]: !opticalSpoofing.value })}
                label="Label"
              />
              <div>
                <p style={{ fontWeight: "bolder", fontSize: '12px' }}>Show Optical Only</p>
              </div>
            </div>
          )}
          {aisSpoofing !== undefined && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                id={'purple'}
                checked={aisSpoofing.value}
                onChange={(e) => onChangeFilter({ [aisSpoofing.name]: !aisSpoofing.value })}
                label="Label"
              />
              <div>
                <p style={{ fontWeight: "bolder", fontSize: '12px' }}>Show AIS Only</p>
              </div>
            </div>
          )}
          {bunkering !== undefined && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                id={'purple'}
                checked={bunkering.value}
                onChange={(e) => onChangeFilter({ [bunkering.name]: !bunkering.value })}
                label="Label"
              />
              <div>
                <p style={{ fontWeight: "bolder", fontSize: '12px' }}>Show Bunkering Only</p>
              </div>
            </div>
          )}

          {
            filterName === "light" &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                id={'purple'}
                checked={sanctionedLight?.value}
                onChange={(e) => onChangeFilter({ [sanctionedLight?.name]: !sanctionedLight?.value })}
                label="Label"
              />
              <div>
                <p style={{ fontWeight: "bolder", fontSize: '12px' }}>Show Sanctioned Ships</p>
              </div>
            </div>
          }

          {
            filterName === "dark" &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                id={'purple'}
                checked={sanctionedDark?.value}
                onChange={(e) => onChangeFilter({ [sanctionedDark?.name]: !sanctionedDark?.value })}
                label="Label"
              />
              <div>
                <p style={{ fontWeight: "bolder", fontSize: '12px' }}>Show Sanctioned Ships</p>
              </div>
            </div>
          }

          {
            filterName === "ais" &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                size="small"
                id={'purple'}
                checked={sanctionedAis?.value}
                onChange={(e) => onChangeFilter({ [sanctionedAis?.name]: !sanctionedAis?.value })}
                label="Label"
              />
              <div>
                <p style={{ fontWeight: "bolder", fontSize: '12px' }}>Show Sanctioned Ships</p>
              </div>
            </div>
          }

          <div className={styles.filterSettings}>
            {dataSlider && (
              <div>
                <i>Vessel Length</i>
                <div>Between</div>
                <div className={styles.slider}>
                  <Slider
                    max={dataSlider.maxValue}
                    getAriaLabel={() => "Vessel Length"}
                    value={valueSlider}
                    onChange={onChangeSlider}
                    valueLabelDisplay="auto"
                    getAriaValueText={() => dataSlider.value}
                  />
                </div>
              </div>
            )}

            {widthSlider && (
              <div>
                <i>Vessel Width</i>
                <div>Between</div>
                <div className={styles.slider}>
                  <Slider
                    max={widthSlider.maxValue}
                    getAriaLabel={() => "Vessel Width"}
                    value={widthValueSlider}
                    onChange={onChangeWidthSlider}
                    valueLabelDisplay="auto"
                    getAriaValueText={() => widthSlider.value}
                  />
                </div>
              </div>
            )}

            {typePicker && (
              <>
                <div style={{ fontSize: '1.4rem', marginBottom: '0.5rem' }}>Type:</div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type"
                  value={typePicker.value}
                  onChange={(e) => onChangeFilter({ [typePicker.name]: e.target.value })}
                  style={{
                    fontSize: '1.2rem',
                    marginBottom: '1rem'
                  }}
                >
                  {aspireTypes.map((elem) => (
                    <MenuItem key={elem} value={elem}>
                      {elem}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}


            {countryPicker &&
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={countryCodes}
                value={selectedFlag}
                onChange={(event, newValue) => {
                  setSelectedFlag(newValue)
                  onChangeFilter({ [countryPicker.name]: newValue.code })
                }}
                sx={{ width: 200, marginLeft: '-1rem', marginBottom: '2rem' }}
                renderOption={(props, { code, label }) => {
                  return (
                    <li {...props} key={code} className="datamanagerListItem">
                      <div className="datamanagerListItem__content">
                        <div className="datamanagerListItem__imgBox">
                          <img
                            src={`https://flagcdn.com/48x36/${code.toLowerCase()}.png`}
                            alt=""
                          />
                        </div>
                        <div className="datamanagerListItem__label">
                          {label}
                        </div>
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <div
                      ref={params.InputProps.ref}
                      style={{ position: "relative" }}
                    >

                      {selectedFlag && (
                        <div className="dataManagerInputFlag">
                          <img
                            src={`https://flagcdn.com/48x36/${selectedFlag.code.toLowerCase()}.png`}
                            alt=""
                          />
                        </div>
                      )}
                      <input
                        type="text"
                        {...params.inputProps}
                        id="datamanagerSearchInput"
                        placeholder="Search By Country"
                      />
                      {selectedFlag && (
                        <div
                          className="datamanagerSearchInputClose"
                          onClick={() => {
                            setSelectedFlag(null)
                            onChangeFilter({ [countryPicker.name]: '' })
                          }


                          }
                        >
                          <CloseIcon sx={{ color: "#333", fontSize: 25 }} />
                        </div>
                      )}
                    </div>
                  );
                }}
              />}

            {filterName === 'unknown' && (
              <>
                <div>
                  <i>Heading</i>
                  <div>Between</div>
                  <div className={styles.slider}>
                    <Slider
                      max={360}
                      getAriaLabel={() => "Vessel Width"}
                      value={headingValueSlider}
                      onChange={onChangeHeadingSlider}
                      valueLabelDisplay="auto"
                      getAriaValueText={() => filters.headingUnknown}
                    />
                  </div>
                </div>

                <div style={{ fontSize: '1.4rem', marginBottom: '0.5rem' }}>Type:</div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type"
                  value={unknownType}
                  onChange={onDarkShipTypeChange}
                  style={{
                    fontSize: '1.2rem',
                    marginBottom: '1rem'
                  }}
                >

                  <MenuItem value='None'>
                    None
                  </MenuItem>
                  <MenuItem value='cargo'>
                    Cargo
                  </MenuItem>
                  <MenuItem value='tanker'>
                    Tanker
                  </MenuItem>
                  <MenuItem value='other'>
                    Other
                  </MenuItem>

                </Select>

                <div style={{ fontSize: '1.4rem', marginBottom: '0.5rem' }}>Is Moving:</div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type"
                  value={unknownIsMoving}
                  onChange={onDarkShipIsMovingChange}
                  style={{
                    fontSize: '1.2rem',
                    marginBottom: '1rem'
                  }}
                >

                  <MenuItem value='None'>
                    None
                  </MenuItem>
                  <MenuItem value='yes'>
                    Yes
                  </MenuItem>
                  <MenuItem value='no'>
                    No
                  </MenuItem>
                </Select>
              </>
            )}

          </div>
        </div>
      )}
    </>
  );
};


export default memo(FilterDataManager);
