import React, { useState, useContext, useEffect, memo } from 'react'

import { Grid, Snackbar } from '@mui/material';

import { TheiaContext } from '../../Theia';
import { SatelliteImagesTimelineDialog } from "../SatelliteImagesTimelineDialog"
import AllShipsEvent from '../AllShipsEvent';
import FuturePathPredictionDialog from './Components/FuturePathPredictionDialog';
import ExtendedPath from './Components/ExtendedPath';

import SatelliteImageDialog from './Components/SatelliteImageDialog';
import CurrentLocation from './Components/CurrentLocation';
import SelectedShips from './Components/DrawerDataTabs/SelectedShips';
import SelectedShipsTabOne from './Components/DrawerDataTabs/SelectedShipsTabOne';
import SelectedShipsTabTwo from './Components/DrawerDataTabs/SelectedShipsTabTwo';
import moment from 'moment';
import SanctionDetails from './Components/Sanction/SanctionDetails';

const ShipDrawerData = ({
  futurePathPredictionValues, setFuturePathPredictionValues, dragSatelliteImageEventHandler, satelliteImageDialogPosition, imgUrl,
  extendedPathDialogPosition, dragExtendedPathEventHandler, selectedShips, setOpenDrawerData, selectedShipsData, shipId
}) => {
  const { viewSatelliteImage } = useContext(TheiaContext)
  const [satelliteImageTimeline, setSatelliteImageTimeline] = useState([])
  const [tabValue, setTabValue] = useState(0);
  const [marrineTrafficImgUrl, setMarineTrafficImgUrl] = useState(null)
  const [isImgDialogOpen, setIsImgDialogOpen] = useState(false)
  const [isPathLoading, setIsPathLoading] = useState(false);
  const [currentLocationPathDialog, setCurrentLocationExtendedPathDialog] = useState(false);
  const [openSatelliteImagesTimelineDialog, setOpenSatelliteImagesTimelineDialog] = useState(false)
  const [showExtendedPathDialog, setShowExtendedPathDialog] = useState(false);
  const [isSpoofingSnackBarOpen, setIsSpoofingSnackBarOpen] = useState(false)
  const [viewSpoofingTimeline, setViewSpoofingTimeline] = useState(false);
  const [extendedStartDate, setExtendedStartDate] = useState(new Date(moment(new Date()).subtract(2, 'days')));
  const [extendedEndDate, setExtendedEndDate] = useState(new Date());

  useEffect(() => {
    if (!viewSatelliteImage) {
      setTabValue(0);
    }
  }, [viewSatelliteImage]);

  const handleChange = (_, newValue) => setTabValue(newValue);

  const showBigMarineTrafficImage = () => {
    setMarineTrafficImgUrl(selectedShips[selectedShips.length - 1].shipImageUrl)
    setIsImgDialogOpen(true)
  }

  const openExtendedPathModal = () => {
    setShowExtendedPathDialog(true);
    setCurrentLocationExtendedPathDialog(false)
  }

  return (
    <>
      <Snackbar
        open={isSpoofingSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSpoofingSnackBarOpen(false)}
        message={'Insufficient data for footprint generation'}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        className="redSnack"
      />

      <FuturePathPredictionDialog
        futurePathPredictionValues={futurePathPredictionValues}
        setFuturePathPredictionValues={setFuturePathPredictionValues}
      />

      <SatelliteImagesTimelineDialog
        openSatelliteImagesTimelineDialog={openSatelliteImagesTimelineDialog}
        satelliteImagesTimeline={satelliteImageTimeline}
        setOpenSatelliteImagesTimelineDialog={setOpenSatelliteImagesTimelineDialog}
      />

      <SatelliteImageDialog
        dragSatelliteImageEventHandler={dragSatelliteImageEventHandler}
        satelliteImageDialogPosition={satelliteImageDialogPosition}
        isImgDialogOpen={isImgDialogOpen}
        setIsImgDialogOpen={setIsImgDialogOpen}
        marrineTrafficImgUrl={marrineTrafficImgUrl}
        imgUrl={imgUrl}
      />

      <ExtendedPath
        extendedPathDialogPosition={extendedPathDialogPosition}
        dragExtendedPathEventHandler={dragExtendedPathEventHandler}
        showExtendedPathDialog={showExtendedPathDialog}
        setShowExtendedPathDialog={setShowExtendedPathDialog}
        viewSpoofingTimeline={viewSpoofingTimeline}
        isPathLoading={isPathLoading}
        setIsPathLoading={setIsPathLoading}
        selectedShips={selectedShips}
        setIsSpoofingSnackBarOpen={setIsSpoofingSnackBarOpen}
        selectedShipsData={selectedShipsData}
        extendedStartDate={extendedStartDate}
        setExtendedStartDate={setExtendedStartDate}
        extendedEndDate={extendedEndDate}
        setExtendedEndDate={setExtendedEndDate}
      />

      <CurrentLocation
        extendedPathDialogPosition={extendedPathDialogPosition}
        dragExtendedPathEventHandler={dragExtendedPathEventHandler}
        currentLocationPathDialog={currentLocationPathDialog}
        setCurrentLocationExtendedPathDialog={setCurrentLocationExtendedPathDialog}
        isPathLoading={isPathLoading}
        setIsPathLoading={setIsPathLoading}
        shipId={shipId}
        selectedShipsData={selectedShipsData}
      />

      <Grid container spacing={0} columnGap={0}>
        <SelectedShips
          tabValue={tabValue}
          handleChange={handleChange}
          selectedShips={selectedShips}
          setOpenDrawerData={setOpenDrawerData}
          selectedShipsData={selectedShipsData}
        />
        {/* 1 */}
        <SelectedShipsTabOne
          tabValue={tabValue}
          showBigMarineTrafficImage={showBigMarineTrafficImage}
          selectedShips={selectedShips}
          imgUrl={imgUrl}
          setIsImgDialogOpen={setIsImgDialogOpen}
          selectedShipsData={selectedShipsData}
        />

        {(tabValue === 0 || tabValue === 1) && (
          <SelectedShipsTabTwo
            imgUrl={imgUrl}
            setIsImgDialogOpen={setIsImgDialogOpen}
            setMarineTrafficImgUrl={setMarineTrafficImgUrl}
            setViewSpoofingTimeline={setViewSpoofingTimeline}
            openExtendedPathModal={openExtendedPathModal}
            viewSatelliteImage={viewSatelliteImage}
            selectedShipsData={selectedShipsData}
            selectedShips={selectedShips}
            setSatelliteImageTimeline={setSatelliteImageTimeline}
            setOpenSatelliteImagesTimelineDialog={setOpenSatelliteImagesTimelineDialog}
            setOpenDrawerData={setOpenDrawerData}
            setIsSpoofingSnackBarOpen={setIsSpoofingSnackBarOpen}
            setShowExtendedPathDialog={setShowExtendedPathDialog}
            setCurrentLocationExtendedPathDialog={setCurrentLocationExtendedPathDialog}
            extendedStartDate={extendedStartDate}
            extendedEndDate={extendedEndDate}
          />
        )}
      </Grid>

      {tabValue === 5 && <AllShipsEvent />}
      {tabValue === 6 && <SanctionDetails selectedShips={selectedShips}/>}
    </>
  )
}

export default memo(ShipDrawerData);
