import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';

import Grid from '@mui/material/Grid';

import { TheiaContext } from '../../../../Theia';
import { Attribution } from '../../../Attribution';
import { TerracottaImageSlider } from '../../../../components/TerracottaImageSlider';
import { frontendAPIURL } from '../../../../utils';

import FuturePathPrediction from '../../../FuturePathPrediction';
import TabData from './Components/TabData';

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const SelectedShipsTabOne = ({
  tabValue, showBigMarineTrafficImage, selectedShips, imgUrl, setIsImgDialogOpen, selectedShipsData
}) => {
  const { setIsAttributionDataUpdated, setShowSpinner } = useContext(TheiaContext)
  const { name, shipImageUrl, imo, mmsi, flag, avgSpeed, shipCategory, moving, maxSpeed, synmax_ship_id,
    year, acquired, type, object_id, initialData } = selectedShipsData.static
  const { heading, speed, destination, eta, location: { lat, lon } } = selectedShipsData.dynamic
  const properties = selectedShips[selectedShips.length - 1].initialData.properties;
  const [attributionData, setAttributionData] = useState(null)

  const getAttributionData = useCallback(
    async () => {

    const token = localStorage.getItem('token')
      try {
        setShowSpinner(true)
        const res = await axios.post(`${frontendAPIURL}/get_attributes`,
          { "object_id": object_id }
          , {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
        setShowSpinner(false)
        const { auto, manual } = res.data

        const attributionDataRows = [
          { id: 1, attributes: 'Length', auto: auto.length, manual: manual.length },
          { id: 2, attributes: 'Heading', auto: auto.heading, manual: manual.heading },
          { id: 3, attributes: 'Ship Type', auto: auto.ship_type, manual: manual.ship_type },
          { id: 4, attributes: 'Moving', auto: auto.moving, manual: manual.moving },
          { id: 5, attributes: 'Attribution', auto: auto.attribution, manual: manual.attribution }
        ]
        setAttributionData(attributionDataRows)
      } catch (err) {
        console.log(err, 'err')
        setShowSpinner(false)
      }
    },
    [object_id, setShowSpinner],
  )

  useEffect(() => {
    object_id !== 'No Info' && object_id !== 0 && getAttributionData()
  }, [selectedShipsData, object_id, getAttributionData])

  const removeAttribution = async () => {

    const token = localStorage.getItem('token')
    try {
      setShowSpinner(true)
      await axios.post(`${frontendAPIURL}/update_attributes`,
        { "object_id": object_id, attribution: null }
        , {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
      setIsAttributionDataUpdated(prevVal => !prevVal)
      setShowSpinner(false)
      getAttributionData()
    } catch (err) {
      console.log(err, 'err')
      setShowSpinner(false)
    }
  }

  const setAttribution = async (allChanges) => {

    const token = localStorage.getItem('token')
    try {
      setShowSpinner(true)
      await axios.post(`${frontendAPIURL}/update_attributes`,
        { "object_id": object_id, ...allChanges }
        , {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
      setIsAttributionDataUpdated(prevVal => !prevVal)
      setShowSpinner(false)
      getAttributionData()
    } catch (err) {
      console.log(err, 'err')
      setShowSpinner(false)
    }
  }

  const DataBlocks = [
    { title: "IMO:", value: imo },
    { title: "MMSI:", value: mmsi },
    { title: "Average Speed:", value: avgSpeed },
    { title: "Speed:", value: `${speed.value} (Acquired on: ${speed.time})` },
    { title: "Destination:", value: destination },
    { title: "ETA:", value: `${eta ? (moment.utc(eta * 1000).format('YYYY-MM-DD HH:mm:ss') + ' UTC') : 'No Info'}` },
    { title: "Heading:", value: heading },
    {
      title: 'Is Moving:',
      value: shipCategory === 'red' ? `${moving ? 'yes' : 'no'}` : null,
    },
    {
      title: 'Max Speed:',
      value: shipCategory === 'red' ? null : maxSpeed,
    },
    { title: 'Synmax Ship Id:', value: synmax_ship_id },
    ...(shipCategory === 'footprint' || shipCategory === 'pink' || shipCategory === 'ais-spoofing'
      ? [{ title: 'Anomaly Type:', value: `${properties.a ? 'AIS' : ''}${properties.f && properties.a ? '/F' : properties.f ? 'F' : ''}${(properties.a || properties.f) && properties.o ? '/O' : properties.o ? 'O' : ''}` }]
      : []),
    { title: 'Build Year:', value: year },
    { title: "Type:", value: type },
    { title: "Object Id:", value: object_id }
  ]

  return (
    <Grid item xs={12} sm={10} md={9} lg={5} xl={5}>
      <div className="drawerData__info">
        {tabValue === 0 && (
          <div className='drawerData__shipInfo'>
            {shipImageUrl &&
              (
                <div className="drawerData__image" onClick={showBigMarineTrafficImage}>
                  <img src={shipImageUrl} alt={name} />
                </div>
              )}
            <div className="dataBox">
              <div className="topLine">
                <div className="topLine__heading">{name}</div>
                {flag && (
                  <div className="topLine__flag">
                    <img
                      src={`https://flagcdn.com/48x36/${flag.toLowerCase()}.png`}
                      alt=""
                    />
                  </div>
                )}
              </div>
              <div className="firstLine">
                {
                  DataBlocks.slice(0, 3).map((data) => <TabData value={data.value} title={data.title} />)
                }
                <div>
                  {
                    DataBlocks.slice(7, 9).map((data) => data.value !== null && <TabData value={data.value} title={data.title} />)
                  }
                </div>
              </div>
              <div className="secondLine">
                {DataBlocks.slice(9, 12).map((data) => <TabData value={data.value} title={data.title} />)}
              </div>
              <div className="thirdLine">
                <div className="textBlock width50percent">
                  <div className="textBlock__title">{(shipCategory === 'footprint' || shipCategory === 'pink' || shipCategory === "ais-spoofing") ? 'Event' : 'Acquired'} Date:</div>
                  <div className="textBlock__value">
                    {(shipCategory === 'footprint' || shipCategory === 'pink' || shipCategory === "ais-spoofing") ? moment.utc(acquired).format('YYYY-MM-DD') : moment.utc(acquired).format('YYYY-MM-DD HH:mm:ss') + ' UTC'}
                  </div>
                </div>
                <div className="thirdLine__right width50percent">
                  {DataBlocks.slice(12, 15).map((data) => <TabData value={data.value} title={data.title} />)}
                  {selectedShips[selectedShips.length - 1].initialData.properties.source_type && (
                    <div className="textBlock">
                      <div className="textBlock__title">Source:</div>
                      <div className="textBlock__value">{capitalizeFirstLetter(selectedShips[selectedShips.length - 1].initialData.properties.source_type)}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {tabValue === 1 && (
          <div className="dataBox dataBox__voyage">
            <div className="firstLine">
              {
                DataBlocks.slice(3, 7).map((data) => <TabData value={data.value} title={data.title} />)
              }
            </div>
            <div className="secondLine">
              <TabData title={"Location:"} value={`Lat:${lat}`} lon={`lon:${lon}`} />
            </div>
          </div>
        )}
        {tabValue === 2 && (
          <div className='attribution'>
            <Attribution imgUrl={imgUrl} setIsImgDialogOpen={setIsImgDialogOpen} object_id={object_id} removeAttribution={removeAttribution} attributionData={attributionData} setAttribution={setAttribution} />
          </div>
        )}
        {tabValue === 3 && <TerracottaImageSlider initialData={initialData} />}
        {tabValue === 4 && <FuturePathPrediction />}
      </div>
    </Grid>
  )
}

export default memo(SelectedShipsTabOne);
