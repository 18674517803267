import React, { useContext } from 'react';
import {
    TileLayer,
    LayersControl,
    ScaleControl,
    withLeaflet
} from 'react-leaflet';
import MouseCoordinates from './MouseCoordinates';
import MeasureControlDefault from 'react-leaflet-measure';
import { TheiaContext } from '../Theia';

const MeasureControl = withLeaflet(MeasureControlDefault);

const TilesAndLeafletFeatures = () => {

  const { mapTheme } = useContext(TheiaContext);
  
  return (
    <>
      <ScaleControl position="bottomleft" />
      <LayersControl position="bottomright">
        <LayersControl.BaseLayer checked={mapTheme === 'satellite'} name="satellite" key="satellite"> 
          <TileLayer
            preferCanvas={true}
            url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3lubWF4IiwiYSI6ImNsc2J6OW5hczBsc3oyaHFyZml6M3JhenoifQ.9pC5AO2dfxg2SUWMfF8D_w"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Mapbox</a> contributors'
          /> 
        </LayersControl.BaseLayer> 

        <LayersControl.BaseLayer checked={mapTheme === 'light'} name="light" key="light"> 
          <TileLayer
            preferCanvas={true}
            url=""
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Mapbox</a> contributors'
          />
        </LayersControl.BaseLayer> 

        <LayersControl.BaseLayer  checked={mapTheme === 'dark'}  name="dark" key="dark"> 
          <TileLayer
            preferCanvas={true}
            url=""
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Mapbox</a> contributors'
          />
        </LayersControl.BaseLayer> 
      </LayersControl>

      <div className="leaflet-top leaflet-left coordinates">
        <MouseCoordinates />
      </div>
    </>
  )
}

export default TilesAndLeafletFeatures