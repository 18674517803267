import L from 'leaflet'
import axios from 'axios';
import moment from 'moment';
import { useEffect } from 'react';

let envSpecificUrl = 'https://frontend-api-j75nm34yfa-uc.a.run.app/'
// let envSpecificUrl = 'https://frontend-api-abekcpaqxa-uk.a.run.app'

// if(process.env.REACT_APP_NGA_ENV === true || process.env.REACT_APP_NGA_ENV === "true") {
//   // use these urls
//   envSpecificUrl = 'https://frontend-api-abekcpaqxa-uk.a.run.app'
// }

export const frontendAPIURL = envSpecificUrl;

export const convertArrToGeojson = (arr) => {
  console.log({
    type: 'FeatureCollection',
    features: arr.map((ship, i) => ({
      type: 'Feature',
      id: i,
      geometry: {
        type: 'Point',
        coordinates: [ship.longitude, ship.latitude],
      },
      properties: {
        ...ship,
      },
    }))
  }, 'geojosn res')
  return {
    type: 'FeatureCollection',
    features: arr.map((ship, i) => ({
      type: 'Feature',
      id: i,
      geometry: {
        type: 'Point',
        coordinates: [ship.longitude, ship.latitude],
      },
      properties: {
        ...ship,
      },
    }))
  }
}

export const getRandomAngleUsingLatLng = (lat, lng) => {
  // This function gives us an angle using the lat/lng values
  // We use it because we don't always get a heading from the database
  // but we need a heading for the UI
  // So we generate a random heading
  // But using a totally random heading causes the ship to rotate on every render(which looks weird)
  // So we generate angles using the ship's lat/lng, so that ship will point in the same direction
  // every single time
  const latString = lat.toString();
  const latValue = latString.slice(-2);
  const lngString = lng.toString();
  const lngValue = lngString.slice(-2);
  const res = parseInt(Math.abs((parseInt(latValue) + parseInt(lngValue)) * 1.55));
  return res;
};

export const pointToLayer = (feature, latlng, type) => {
  let { properties, isClicked, } = feature
  let { heading, speed, shipCategory, isDeselected, bunkering, spoofingShips, fromDecoupleModel, ship1Status, ship2Status, frontend_rotation, sanctionedShip } = properties;

  if(type === 'pink') {
    return L.canvasMarker(latlng, {
      radius: 20,
      img: {
        url: './seethroughPinky.svg',
        size: [35, 35],
        rotate: heading,
        opacity: 0.2
      }
    })
  }
  if(type === 'ais-spoofing') {
    return L.canvasMarker(latlng, {
      radius: 20,
      img: {
        url: './seethroughPinky.svg',
        size: [35, 35],
        rotate: heading,
        opacity: 0.2
      }
    })
  }

  if(type === 'footprint') {
    return L.canvasMarker(latlng, {
      radius: 20,
      img: {
        url: './seethroughPinky.svg',
        size: [35, 35],
        rotate: heading,
        opacity: 0.2
      }
    })
  }

  if (type === 'selected') {
    if(fromDecoupleModel) {
      switch (ship1Status + ship2Status) {
        case 'redblue':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './redBlueBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })

        case 'bluered':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './blueRedBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })
        case 'redred':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './redRedBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })
        case 'orangered':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './orangeRedBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })
        case 'redorange':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './redOrangeBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })
        case 'orangeblue':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './orangeBlueBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })
        case 'blueorange':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './blueOrangeBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })
        case 'orangeorange':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './orangeOrangeBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })
        case 'blueblue':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './blueBlueBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })

        case 'aisais':
          return L.canvasMarker(latlng, {
            radius: 20,
            img: {
              url: './aisBunkering.svg',
              size: [50, 30],
              opacity: 0.5,
              rotate: frontend_rotation
            }
          })  

        default:
          return L.marker(latlng)

      }
    } else {
    if(shipCategory === 'pink') {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './seethroughPinky.svg',
          size: [35, 35],
          rotate: heading,
          opacity: 0.2
        }
      })
    }
  
    if(shipCategory === 'footprint') {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './seethroughPinky.svg',
          size: [35, 35],
          rotate: heading,
          opacity: 0.2
        }
      })
    }

      if (shipCategory === 'ais-spoofing') {
        return L.canvasMarker(latlng, {
          radius: 20,
          img: {
            url: './seethroughPinky.svg',
            size: [35, 35],
            rotate: heading,
            opacity: 0.2
          }
        })
      }

    if (shipCategory === 'orange' && !sanctionedShip) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './orangeEyeFilled.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }

    if (shipCategory === 'orange' && sanctionedShip) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './sanctionedDark.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }

    if (shipCategory === 'blue' && !sanctionedShip) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './blueEyeFilled.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }

    if (shipCategory === 'blue' && sanctionedShip) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './sanctionedLight.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }

    if (shipCategory === 'ais' && !sanctionedShip) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './green-triangle-filled.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }

    if (shipCategory === 'ais' && sanctionedShip) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './sanctionedAis.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }

    if (shipCategory === 'red') {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './redEyeFilled.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }
  }
}
  if (type === 'ais') {
    if(sanctionedShip) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './sanctionedAis.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }
    if(spoofingShips && spoofingShips.length > 0) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './pink-triangle.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    } else {
    if (speed < 0.2) {
      return L.circleMarker(latlng, {
        color: 'green'
      })
    } else {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './green-triangle.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }
    }

  }

  if (type === 'red') {
    if(isDeselected) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './redEyeFaded.svg',
          size: [20, 30],
          rotate: heading,
          fillOpacity: '0.3'
        }
      })
    } else {
      if(bunkering) {
        return L.canvasMarker(latlng, {
          radius: 20,
          img: {
            url: './bunkeringRed.svg',
            size: [20, 30],
            rotate: heading,
            fillOpacity: '0.3'
          }
        })
      } else {
        return L.canvasMarker(latlng, {
          radius: 20,
          img: {
            url: './redEye.svg',
            size: [20, 30],
            rotate: heading,
            fillOpacity: '0.3'
          }
        })
      }
 
    }

  }

  if (type === 'blueDetections') {
    if(isDeselected && !sanctionedShip) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './blueEyeFaded.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    } if(isDeselected && sanctionedShip) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './sanctionedLight.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    } else {
      if(bunkering) {
        return L.canvasMarker(latlng, {
          radius: 20,
          img: {
            url: './bunkeringBlue.svg',
            size: [20, 30],
            rotate: heading,
            opacity: 0.5
          }
        })
      } if(sanctionedShip) {
        return L.canvasMarker(latlng, {
          radius: 20,
          img: {
            url: './sanctionedLight.svg',
            size: [20, 30],
            rotate: heading,
            opacity: 0.5
          }
        })
      }
       else {
        return L.canvasMarker(latlng, {
          radius: 20,
          img: {
            url: './blueEye.svg',
            size: [20, 30],
            rotate: heading,
            opacity: 0.5
          }
        })
      }
 
    }
  
  }
  if (type === 'purpleDetections') {
    if(isDeselected) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './purpleEye.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    } else {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './purpleEye.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    }
  
  }

  if (type === 'orange') {
    if(isDeselected) {
      return L.canvasMarker(latlng, {
        radius: 20,
        img: {
          url: './orangeEyeFaded.svg',
          size: [20, 30],
          rotate: heading,
          opacity: 0.5
        }
      })
    } else {
      if(bunkering) {
        return L.canvasMarker(latlng, {
          radius: 20,
          img: {
            url: './bunkeringOrange.svg',
            size: [20, 30],
            rotate: heading,
            opacity: 0.5
          }
        })
      } if(sanctionedShip) {
        return L.canvasMarker(latlng, {
          radius: 20,
          img: {
            url: './sanctionedDark.svg',
            size: [20, 30],
            rotate: heading,
            opacity: 0.5
          }
        })
      }
       else {
        return L.canvasMarker(latlng, {
          radius: 20,
          img: {
            url: './orangeEye.svg',
            size: [20, 30],
            rotate: heading,
            opacity: 0.5
          }
        })
      }
 
    }

  }
}

function compare(a, b) {
  if (a.timestamp < b.timestamp) {
    return -1;
  }
  if (a.timestamp > b.timestamp) {
    return 1;
  }
  return 0;
}


export const getShipDetails = async (feature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, shipCategory, date, isCurrent = false, resData) => {
  
  console.log(feature, "featurefeature")
  let { synmax_ship_id, timestamp, attribution, acquired, day } = feature.properties;
  const token = localStorage.getItem('token')
  setShowSpinner(true)
  const oldIndexValue = feature.oldIndexValue;

  let shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id
  if (!shipId) {
    shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.attribution
  }

  // Same thing comes as different values from the API,
  // so we need to makes below adjustments using if checks
  if (!synmax_ship_id) {
    synmax_ship_id = attribution
  }

  console.log(isCurrent, "isCurrent")

  if (!acquired) {
    acquired = timestamp * 1000
  }

  if (!acquired && !timestamp) {
    acquired = day
  }
  const dateSting = moment.utc(acquired).format('YYYY-MM-DD')

  let shipIndex = 1;
  if (selectedShips.length > 0) {
    let highestValue = 0
    selectedShips.forEach(ship => {
      if (ship.shipIndex > highestValue) {
        highestValue = ship.shipIndex
      }
    })
    shipIndex = highestValue + 1
  }

  if(oldIndexValue) {
    // When bunkering we don't want a new index
    shipIndex = oldIndexValue
  }

  const finalShipDataObject = {
    color: getUniqueColor(colorArr, setColorArr),
    initialData: { ...feature, isClicked: true },
    shipIndex,
    showCircle: true,
    visible: true,
    pathVisible: true,
    shipCategory,
    isSearchedShip: false
  }

  console.log(selectedShips, ':selectedShips onclick')

  const shipInfoPromise = axios.post(`${frontendAPIURL}/ship_info`,
    { "id": "frontend", "start": `${dateSting} 00:00:00`, "end": `${dateSting} 23:59:59`, "shipids": [synmax_ship_id] }
    , {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })

  const startTimestamp = acquired - (24 * 60 * 60 * 1000)
  const pathPromise = axios.post(`${frontendAPIURL}/ais_for_ships`,
    { "id": "afrontend", "start": `${moment.utc(startTimestamp).format('YYYY-MM-DD HH:mm:ss')}`, "end": `${moment.utc(acquired).format('YYYY-MM-DD HH:mm:ss')}`, "shipids": [synmax_ship_id] }
    , {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })

  const shipMarineTrafficImagePromise = axios.get(`${frontendAPIURL}/ship_pic?shipid=${synmax_ship_id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
    responseType: 'blob'
  });

  const res = await Promise.allSettled([shipInfoPromise, pathPromise, shipMarineTrafficImagePromise])

  if (res[0].status === 'rejected') {
    setIsSnackbarOpen(true)
  } else {

    finalShipDataObject.shipData = res[0].value.data;
    if (res[1].status === 'fulfilled') {
      finalShipDataObject.path = res[1].value.data.sort(compare);
    } else {
      finalShipDataObject.path = []
    }
    if (res[2].status === 'fulfilled') {
      const shipImageUrl = URL.createObjectURL(res[2].value.data);
      finalShipDataObject.shipImageUrl = shipImageUrl;
    } else {
      finalShipDataObject.shipImageUrl = '';
    }

    console.log("finalShipDataObject", selectedShips);

    if(oldIndexValue) {
      setSelectedShips([...selectedShips.slice(0,-1), finalShipDataObject])
    } else {
      const oldData = [...selectedShips, finalShipDataObject]
      console.log(oldData, "oldData")

      const filteredObjects = {};

      oldData.forEach(obj => {
        const shipId = obj.initialData.properties.attribution || obj.initialData.properties.synmax_ship_id;
        const objDate = obj.initialData.properties.Date;
        const targetDateString = date;

        if (!filteredObjects[shipId] || objDate === targetDateString) {
          filteredObjects[shipId] = obj;
        }
      });

      const resultArray = Object.values(filteredObjects);

      const newDd = resultArray?.map((item) => {
        if ((item.initialData.properties.attribution || item.initialData.properties.synmax_ship_id) === shipId) {

          return {
            ...item,
            initialData: {
              ...item.initialData, 
              properties: {
                ...item.initialData.properties,
                // acquired: item.initialData.properties.timestamp ? item.initialData.properties.timestamp * 1000 : item.initialData.properties.acquired
              }
            },
            path: resData
          }

        } else return item
      })

      console.log(newDd, "selectedShips00000")

      setSelectedShips((prev) => [...prev, finalShipDataObject])
      if (isCurrent) {
        setSelectedShips(newDd)
      }
    }
  }

  setShowSpinner(false)
  setSelectShipWaiting(false)
  return;
}


function getRandomColor() {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  var c = color.substring(1) // strip #
  var rgb = parseInt(c, 16) // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff // extract red
  var g = (rgb >> 8) & 0xff // extract green
  var b = (rgb >> 0) & 0xff // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  if (luma < 40) {
    // pick a different colour
    getRandomColor()
  } else {
    return color
  }
}

export function getUniqueColor(colorArr, setColorArr) {
  let candidateColor = ''

  const /**@type {number} */ maxIterations = 20
  let iterationCounter = 0

  do {
    candidateColor = getRandomColor() //get a new random color
  } while (iterationCounter++ < maxIterations && colorArr.includes(candidateColor)) //repeat if the color is already used

  //add the found color to the state
  setColorArr((pastColors) => {
    const newColors = [...pastColors, candidateColor]

    return newColors
  })
  if (candidateColor) {
    return candidateColor //return the color that has not been used already
  } else {
    return '#ccc'
  }
}

export const getSatelliteImageRequest = async (objectId) => {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.get(`${frontendAPIURL}/ship_clip?object_id=${objectId}`,{
      headers: {
        Authorization: 'Bearer ' + token,
      },
      responseType: 'blob'
    });
    return URL.createObjectURL(res.data);
  } catch(error) {
    return ""
  }
}

export const getObservedShipsRequest = async (start, end, arrShipIds) => {
  const token = localStorage.getItem('token');
  try {
    const res = await axios.post(
      `${frontendAPIURL}/objects_for_ships`,
      {
        id: 'frontend',
        start,
        end,
        shipids: arrShipIds,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export const getAllShipEventsRequest = async (start, end, ShipIds, setBunkeringData, setAisBunkeringData, setSatelliteData, setSelectedShips) => {
  const token = localStorage.getItem('token');
  const initialType = ["footprint-spoofing", "optical_sts", "ais_sts", "opt-spoofing", "spoofing"]
  const res = await axios.post(`${frontendAPIURL}/get_events`,
    {
      "types": initialType,
      "shipids": ShipIds,
      start,
      end
    }, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  // setBunkeringData({ events: res.data.optical_sts, key: parseInt(Math.random() * 10000) })
  // setAisBunkeringData({ events: res.data.ais_sts, key: parseInt(Math.random() * 10000) })

  setSelectedShips((prev) => ([...prev.slice(0, -1), { ...prev[prev.length - 1], extendedPathSpoofing: [...res.data.spoofing, ...res.data.optical_sts, ...res.data.ais_sts] }]))

  if (res.data) {

    const getValue = async (next) => {
      const allInitialShips = res.data[next]

      // setSelectedShips((prev) => ([...prev.slice(0, -1), { ...prev[prev.length - 1], extendedPathSpoofing: allInitialShips }]))

      console.log(allInitialShips, "allInitialShips")

      const allShipIds = allInitialShips.map(ship => ship.synmax_ship_id || ship.synmax_ship_id_1 || ship.synmax_ship_id_2 || ship.ship1_attribution || ship.ship2_attribution)
      const shipInfoRes = await axios.post(`${frontendAPIURL}/ship_info`,
        { "id": "frontend", "shipids": allShipIds.filter(item => item !== 'None') }
        , {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }).catch(() => {
          console.log("Error");
        })

      let uniqueShipMmsis = []
      let allUniqueShips = []
      let uniqueShipIds = []

      shipInfoRes.data.static.forEach(ship => {
        if (uniqueShipMmsis.includes(ship.mmsi)) {
          return;
        } else {
          uniqueShipMmsis.push(ship.mmsi)
          allUniqueShips.push(ship)
        }

        if (uniqueShipIds.includes(ship.synmax_ship_id)) {
          return;
        } else {
          uniqueShipIds.push(ship.synmax_ship_id)
        }
      })
      const detectionsWithInfo = []
      const detectionsWithoutInfo = []
      allInitialShips.forEach(ship => {
        if (uniqueShipIds.includes(ship.synmax_ship_id)) {
          // has ship info
          const shipInfo = allUniqueShips.filter(uniqueShip => uniqueShip.synmax_ship_id === ship.synmax_ship_id)[0]
          detectionsWithInfo.push({ ...ship, staticLength: shipInfo.length, staticWidth: shipInfo.width, staticType: shipInfo.ship_type, staticFlagCode: shipInfo.flag, name: shipInfo?.name, destination: shipInfo?.destination, imo: shipInfo.imo })
        } else {
          // doesn't have ship_info
          detectionsWithoutInfo.push(ship)
        }
      })

      const updatedFootprintSpoofingShips = { initialShips: detectionsWithInfo, noShipInfo: detectionsWithoutInfo }
      return updatedFootprintSpoofingShips
    }

    return {
      "footprint-spoofing": await getValue('footprint-spoofing'),
      "optical_sts": await getValue('optical_sts'),
      "opt-spoofing": await getValue('opt-spoofing'),
      "spoofing": await getValue('spoofing'),
      "ais_sts": await getValue('ais_sts')
    }

  }
}

export const getShipsPathRequest = async (start, end, shipIds) => {
  const token = localStorage.getItem('token');
  try {
    const res = await axios.post(
      `${frontendAPIURL}/ais_for_ships`,
      {
        id: 'bfrontend',
        start,
        end,
        shipids: shipIds,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }

}

export const toHoursAndMinutes = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { h: hours, m: minutes, s: seconds };
}

export const getShipInfoUsingObjectId= async (newValue, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr,setColorArr, setIsSnackbarOpen, setSelectShipWaiting, setMapCenter, setOpenSatelliteImagesTimelineDialog) => {
  const token = localStorage.getItem('token')
  const res = await axios.post(`${frontendAPIURL}/objects`,
    { "id": "frontend", "objects": [newValue] }
    ,{
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })

    const data = res.data[0];

    // return res;
    let shipCategory;

    if(data.attribution === "None"){
      shipCategory = "red"
    }else if(data.attribution && !data.dark){
      shipCategory = "blue"
    } else if(data.attribution && data.dark){
      shipCategory = "orange"
    }

    if(shipCategory !== "red"){
      const observationGeoJsonFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [data.longitude, data.latitude],
        },
        properties: {
          ...data,
        },
      }

      getShipDetails(observationGeoJsonFeature,setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, shipCategory )
      setMapCenter({ lat: data.latitude, lng: data.longitude, zoomLevel: 12 })
      setOpenSatelliteImagesTimelineDialog(false)
    } else {
      let shipIndex = 1;
      if (selectedShips.length > 0) {
        shipIndex = selectedShips[selectedShips.length - 1].shipIndex + 1
      }

      const observationGeoJsonFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [data.longitude, data.latitude],
        },
        properties: {
          ...data,
        },
      }
      setSelectedShips([ ...selectedShips, {shipCategory: 'red',initialData: {...observationGeoJsonFeature, isClicked: true}, shipIndex, visible: true, pathVisible: true, shipData: {...observationGeoJsonFeature.properties, name: 'Dark Ship'}, showCircle: true, path: [], color: getUniqueColor(colorArr, setColorArr)}])
      setMapCenter({ lat: data.latitude, lng: data.longitude, zoomLevel: 12 })
      setOpenSatelliteImagesTimelineDialog(false)
    }
}

export const onEachCoverageFeature = (feature, layer, setCoverageSourceId) => {
  layer.on({
    click: async function (e) {
      console.log(feature, ':feature')
      setCoverageSourceId(feature.properties.image_id)
    }})
}