import React, { useState, useCallback, useContext, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import debounce from "lodash.debounce";
import axios from 'axios'
import moment from 'moment'

import { frontendAPIURL, getShipDetails, getShipInfoUsingObjectId, getUniqueColor } from '../utils'
import { TheiaContext } from '../Theia';
import CustomModal from './CustomModel';
const defaultOptions = [{ label: "Search Ship Id", value: 'shipId' }, { label: "Search Object Id", value: 'objectId' }]

const _filterOptions = createFilterOptions();
const filterOptions = (options, state) => {
  const results = _filterOptions(options, state);

  if (!results.includes(defaultOptions[0])) {
    results.unshift(defaultOptions[0]);
  }

  if (!results.includes(defaultOptions[1])) {
    results.unshift(defaultOptions[1]);
  }

  return results;
};

export const SearchBar = ({date}) => {
  const { selectedShips, setSelectedShips, showSpinner, setShowSpinner, setHaveShipInfo, isLiveAisView, setIsSnackbarOpen, colorArr, setColorArr,setMapCenter,setSelectShipWaiting, setCurrentLocationData } = useContext(TheiaContext);
  const [shipToBeSearched, setShipToBeSearched] = useState({ label: '', id: '' })
  const [searchOptions, setSearchOptions] = useState([])
  const [inputSearchValue, setInputSearchValue] = useState('')
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const getOptionsDelayed = useCallback(
    debounce((query) => {
      // set new arr here
      getNewOptions(query)
    }, 200),
    []
  );

  const getNewOptions = async (searchValue) => {

    setShowSpinner(true)
   
    const token = localStorage.getItem('token')
    try {
      const res = await axios.post(`${frontendAPIURL}/search`, { "id": "test_ships", "query": searchValue }
        , {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

      if (res.data.length > 0) {
        setShowSpinner(false)
        let searchOptions = res.data.filter(ship => (ship.ship_name && (ship.ship_name !== "nan")))
        searchOptions.push({ship_name: 'Find Synmax Ship Id', synmax_ship_id: 'ccvb', type: 'Name', value: 'cvbdf'})

        searchOptions = searchOptions.map(({ ship_name, synmax_ship_id, type, value }, i) => ({ key: i, label: ship_name + (type === 'mmsi' ? ` (${type} :${value})` : '') + (type === 'imo' ? ` (${type} :${value})` : ''), id: synmax_ship_id, type, value }))
        console.log(searchOptions, 'searchOptions')
          setSearchOptions(searchOptions)
      }
    } catch (err) {
      setShowSpinner(false)
      console.log(err, 'err')
    }
  }

  const loadSearchedShip = async (newValue, date) => {

    let dateToUse = date * 1000;

    if(typeof date !== 'number') {
      dateToUse = date.$d
    }
    const dateString = moment(dateToUse).format('YYYY-MM-DD')
    const token = localStorage.getItem('token')
    setShowSpinner(true)
    const res = await axios.post(`${frontendAPIURL}/ais_for_ships`,
      { "id": "dfrontend", "start": `${dateString} 00:00:00`, "end": `${dateString} 23:59:59`, "shipids": [newValue.id] }
      , {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
    setShowSpinner(false)
    if(res.data.length > 0) {
      const observationsSortedByTimestamp = res.data.sort(compare)
      const latestObservation = observationsSortedByTimestamp[observationsSortedByTimestamp.length - 1]
      const observationGeoJsonFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [latestObservation.longitude, latestObservation.latitude],
        },
        properties: {
          ...latestObservation,
        },
      }
      setShipToBeSearched({ label: "", id: "" })
      setMapCenter({ lat: latestObservation.latitude, lng: latestObservation.longitude, zoomLevel: 12 })
      setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
      getShipDetails(observationGeoJsonFeature,setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting,'ais' )
    } else {
      setShipToBeSearched({ label: "", id: "" })
      setIsSnackbarOpen(true)
    }
  }

  const searchForLiveAis = async (val) => {
    setShowSpinner(true)
    const token = localStorage.getItem('token')
    const res = await axios.post(`${frontendAPIURL}/live_ships`,
      { "id": "frontend", "shipids": [val.id] }
      , {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
    setShowSpinner(false)
    loadSearchedShip(val, res.data[0].timestamp)
  }

  const getShipInfoUsingShipId = async () => {
    console.log(inputSearchValue, "inputSearchValue")

    setShowSpinner(true);
    const token = localStorage.getItem('token');

    try {
      const res = await axios.post(`${frontendAPIURL}/live_ships`, {
        id: "frontend",
        shipids: [inputSearchValue],
      }, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      if (res.data && res.data.length > 0) {
        loadSearchedShip({ id: inputSearchValue }, res.data[0].timestamp);
      } else {
        setShipToBeSearched({ label: "", id: "" })
        setModalTitle('No Data Available');
        setModalMessage('No data available for this ship ID.');
        setModalOpen(true);
      }
    } catch (error) {
      setShipToBeSearched({ label: "", id: "" })
      setModalTitle('No Data!');
      setModalMessage('Invalid search. Please provide a valid ship ID.');
      setModalOpen(true);
    } finally {
      setShowSpinner(false);
    }
  };

  const getDetectionWithShipId = async () => {
    console.log(inputSearchValue, "inputSearchValue")
    const token = localStorage.getItem('token');

    try {
      const res = await axios.post(`${frontendAPIURL}/objects`, {
        id: "frontend",
        objects: [inputSearchValue],
      }, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      if (res.data && res.data.length > 0) {
        setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
        getShipInfoUsingObjectId(inputSearchValue, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, setMapCenter);
      } else {
        setShipToBeSearched({ label: "", id: "" })
        setModalTitle('No Data Available');
        setModalMessage('No data available for this object ID.');
        setModalOpen(true);
      }
    } catch (error) {
      setShipToBeSearched({ label: "", id: "" })
      setModalTitle('No Data!');
      setModalMessage('Invalid search. Please provide a valid object ID.');
      setModalOpen(true);
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <>
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={[...defaultOptions,  ...searchOptions]}
      filterOptions={filterOptions}
      onInputChange={async (event, newInputValue) => {
        setInputSearchValue(newInputValue);
        if (newInputValue.length > 2) {
          await getOptionsDelayed(newInputValue);
        } else {
          setSearchOptions([]);
        }
      }}
      onChange={(event, newValue) => {
        console.log(newValue, ':newValue')
        if (newValue.label === "Search Ship Id" || newValue.label === "Search Object Id") {
          setShipToBeSearched({ label: newValue.label === "Search Ship Id" ? "Search Ship Id" : newValue.label === "Search Object Id" ? "Search Object Id" : "" , id: ""})
        } else {
          setShipToBeSearched(newValue);
        }

        if (newValue) {
          if (newValue.value === 'objectId') {
            getDetectionWithShipId(newValue)
          } else {
            if (newValue.value === 'shipId') {
              getShipInfoUsingShipId(newValue)
            } else {
              if (isLiveAisView) {
                searchForLiveAis(newValue)
              } else {
                loadSearchedShip(newValue, date)
              }
            }
          }
        }
      }}
      value={shipToBeSearched}
      renderOption={(props, { id, label, type, value, key }) => {
        return (
          <li {...props} key={key}>
            <span style={{ fontSize: 16 }}>{label}</span>
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <div
            ref={params.InputProps.ref}
            style={{ position: "relative" }}
          >
            <div className="svgBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 15.663 18.629"
              >
                <path
                  id="Path_37"
                  data-name="Path 37"
                  d="M6.316,0a5.9,5.9,0,0,1,4.466,2.145,7.928,7.928,0,0,1,1.85,5.18,7.964,7.964,0,0,1-1.715,5.018.528.528,0,0,1,.056.055l4.547,5.266a.63.63,0,0,1,0,.8.441.441,0,0,1-.688,0L10.286,13.2a.568.568,0,0,1-.074-.108,5.766,5.766,0,0,1-3.9,1.56A5.9,5.9,0,0,1,1.85,12.505,7.929,7.929,0,0,1,0,7.325a7.928,7.928,0,0,1,1.85-5.18A5.9,5.9,0,0,1,6.316,0Zm3.76,2.965a4.963,4.963,0,0,0-3.76-1.806,4.964,4.964,0,0,0-3.76,1.806A6.675,6.675,0,0,0,1,7.325a6.675,6.675,0,0,0,1.557,4.361,4.963,4.963,0,0,0,3.76,1.806,4.963,4.963,0,0,0,3.76-1.806,6.674,6.674,0,0,0,1.557-4.361,6.675,6.675,0,0,0-1.557-4.361Z"
                  transform="translate(0)"
                  fill="#23283d"
                />
              </svg>
            </div>
            <input
              type="text"
              {...params.inputProps}
              id="searchInput"
              placeholder="Search by name,IMO,ShipId,objectId or MMSI"
            />
            {shipToBeSearched?.label !== "" && (
              <div
                className="closeIconAttributionSearch"
                onClick={() =>
                  setShipToBeSearched({ label: "", id: "" })
                }
              >
                <CloseIcon sx={{ color: "#333", fontSize: 20 }} />
              </div>
            )}
          </div>
        );
      }}
    />

      <CustomModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={modalTitle}
        message={modalMessage}
      />

    </>
  )
}


function compare( a, b ) {
  if ( a.timestamp < b.timestamp ){
    return -1;
  }
  if ( a.timestamp > b.timestamp ){
    return 1;
  }
  return 0;
}