import React, { useEffect, useContext, useState} from 'react'
import { GeoJSON, Pane, Polyline, Tooltip } from 'react-leaflet';
import axios from 'axios';
import { frontendAPIURL } from '../../utils';
import { ShipInfoResponse } from '../utils/ShipInfoResponse';
import moment from 'moment';
import * as turf from '@turf/turf';

import { TheiaContext } from '../../Theia'
import { filterPinkOrangeAndBlueShips } from '../AllRenderedShips/utils';

const SatelliteCoverageSpoofing = ({date}) => {
  const { setFootprintShips, satelliteData, selectedShips, spoofingHeatmapVisible, setShowSpinner, filters, deselectedShipsObjectIds, setSpoofingDetections, AisSpoofingShips, setAisSpoofingShips, satelliteTimelineData, spoofingHeatmapTimelineVisible } = useContext(TheiaContext);
  const [shipIds, setShipIds] = useState([])
  const [shipData, setShipData] = useState([])

    useEffect(() => {
      getFootprintSpoofing()
    },[date])

    const getData = (aisSpoofingData,uniqueShipIds,allUniqueShips) =>{
      const detectionsWithInfo = []
      const detectionsWithoutInfo = []
      aisSpoofingData.forEach(ship => {
        if (uniqueShipIds.includes(ship.synmax_ship_id)) {
          // has ship info
          const shipInfo = allUniqueShips.filter(uniqueShip => uniqueShip.synmax_ship_id === ship.synmax_ship_id)[0]
          detectionsWithInfo.push({ ...ship, staticLength: shipInfo.length, staticWidth: shipInfo.width, staticType: shipInfo.ship_type, staticFlagCode: shipInfo.flag, name: shipInfo?.name, destination: shipInfo?.destination, imo: shipInfo.imo })
        } else {
          // doesn't have ship_info
          detectionsWithoutInfo.push(ship)
        }
      })

      const updatedFootprintSpoofingShips = { initialShips: detectionsWithInfo, noShipInfo: detectionsWithoutInfo }
      return updatedFootprintSpoofingShips
    }

  const getFootprintSpoofing = async () => {
    setShowSpinner(true)
    const token = localStorage.getItem('token')
    const dateString = moment(date.$d).format('YYYY-MM-DD')

    const res = await axios.post(`${frontendAPIURL}/get_events`, {
      types: ['spoofing'], "start": `${dateString} 00:00:00`, "end": `${dateString} 23:59:59`,
    }
      , {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

    if (res.data['spoofing']) {
      // const parsedGeoJSON = JSON.parse(res.data['footprint-spoofing'][0].geojson)
     // setFootprintShips({ ships: res.data['footprint-spoofing'], key: parseInt(Math.random() * 10000) })
      const allInitialShips = res.data['spoofing']
      const allShipIds = allInitialShips.map(ship => ship.synmax_ship_id)
      const footprintShipIds = allInitialShips?.filter(ship => ship.f === true)?.map((ship) => ship.synmax_ship_id);

        const newRes = await axios.post(`${frontendAPIURL}/get_events`, {
          types: ['footprint'], "start": `${dateString} 00:00:00`, "end": `${dateString} 23:59:59`, shipids: footprintShipIds
        }
          , {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }).catch(() => {
            setShowSpinner(false)
          })

      // setFootprintGeojsonArray(newRes?.data?.['footprint'])
      const filterShipIds = shipIds.length === 0 ? allShipIds : allShipIds.filter(id => !shipIds.includes(id))
      const { allUniqueShips, uniqueShipIds } = await ShipInfoResponse(filterShipIds, setShowSpinner, { shipIds: shipIds, shipData })
      setShipIds(uniqueShipIds)
      setShipData(allUniqueShips)

      let aisSpoofingData = []
      let footprintSpoofingData = []
      let opticalSpoofingData = []

      allInitialShips.forEach(ship=>{
        if(ship.a === true){
          aisSpoofingData.push(ship)
        }if(ship.f === true){
          footprintSpoofingData.push(ship)
        }if(ship.o === true){
          opticalSpoofingData.push(ship)
        }
      })

      //AIS Spoofing Data
      if (aisSpoofingData.length > 0) {
        const updatedAisSpoofingShips = getData(aisSpoofingData, uniqueShipIds, allUniqueShips)
        const { lengthFootprint, widthFootprint, typeFootprint, countryFootprint, noImoFootprint } = filters;

        filterPinkOrangeAndBlueShips(lengthFootprint, widthFootprint, typeFootprint, countryFootprint, updatedAisSpoofingShips, setAisSpoofingShips, deselectedShipsObjectIds, noImoFootprint, false)
      }

    setShowSpinner(true)  

      async function fetchData() {
        try {
          setTimeout(async () => {
            const updatedFootprintArray = await newRes?.data?.['footprint']?.map(item1 => {
              const matchingItem = footprintSpoofingData?.find(item2 => item1.synmax_ship_id === item2.synmax_ship_id);
              return { ...item1, ...matchingItem };
            });

            setShowSpinner(false)

            const updatedFootprintSpoofingShips = await getData(updatedFootprintArray, uniqueShipIds, allUniqueShips)
            const { lengthFootprint, widthFootprint, typeFootprint, countryFootprint, noImoFootprint } = filters;

            filterPinkOrangeAndBlueShips(lengthFootprint, widthFootprint, typeFootprint, countryFootprint, updatedFootprintSpoofingShips, setFootprintShips, deselectedShipsObjectIds, noImoFootprint, false)

          }, 1000); 

        } catch (error) {
          console.error(error.message);
          setShowSpinner(false)
        }
      }

      // Call the fetchData function to start the process
      fetchData();

      //Optical Spoofing

      if(opticalSpoofingData.length > 0){
        const updatedSpoofingShips = getData(opticalSpoofingData,uniqueShipIds,allUniqueShips )
        const {  lengthSpoofing, widthSpoofing, typeSpoofing, countrySpoofing, noImoSpoofing} = filters;

        filterPinkOrangeAndBlueShips(lengthSpoofing, widthSpoofing, typeSpoofing, countrySpoofing, updatedSpoofingShips, setSpoofingDetections, deselectedShipsObjectIds, noImoSpoofing, false)
    }

      // const detectionsWithInfo = []
      // const detectionsWithoutInfo = []
      // allInitialShips.forEach(ship => {
      //   if (uniqueShipIds.includes(ship.synmax_ship_id)) {
      //     // has ship info
      //     const shipInfo = allUniqueShips.filter(uniqueShip => uniqueShip.synmax_ship_id === ship.synmax_ship_id)[0]
      //     detectionsWithInfo.push({ ...ship, staticLength: shipInfo.length, staticWidth: shipInfo.width, staticType: shipInfo.ship_type, staticFlagCode: shipInfo.flag, name: shipInfo?.name, destination: shipInfo?.destination, imo: shipInfo.imo })
      //   } else {
      //     // doesn't have ship_info
      //     detectionsWithoutInfo.push(ship)
      //   }
      // })

      // const updatedFootprintSpoofingShips = { initialShips: detectionsWithInfo, noShipInfo: detectionsWithoutInfo }
  }
  }

    const getStyle = (feature) => {
        const { confidence } = feature.properties;
        return { weight: 1, fillColor: heatMapColorforValue(confidence / 100), fillOpacity: 0.2, color: '#ccc' }
    }

  const limeOptions = { color: 'lime' }
  const currentlySelectedShip = selectedShips[selectedShips.length - 1]

  const polygons = satelliteData?.geojson?.features || [];

  const occurrenceArray = polygons.map((polygon) => polygon.properties.confidence);

  const maxConfidenceIndex = occurrenceArray.indexOf(Math.max(...occurrenceArray));

  const center = polygons?.length > 0 && turf.center(polygons[maxConfidenceIndex]);

  const polygonsTimeline = satelliteTimelineData?.geojson?.features || [];

  const occurrenceTimelineArray = polygonsTimeline.map((polygon) => polygon.properties.confidence);

  const maxConfidenceTimelineIndex = occurrenceTimelineArray.indexOf(Math.max(...occurrenceTimelineArray));

  const centerTimeline = polygonsTimeline?.length > 0 && turf.center(polygonsTimeline[maxConfidenceTimelineIndex]);

  useEffect(() => {
    console.log(satelliteData, "satelliteData")
  }, [satelliteData])

    return (
      <div>
        {
          satelliteTimelineData.geojson !== null && selectedShips.length > 0 && (
            <>
            {spoofingHeatmapTimelineVisible && <GeoJSON data={satelliteTimelineData.geojson} style={getStyle} key={satelliteTimelineData.key} />}
            {spoofingHeatmapTimelineVisible && currentlySelectedShip.shipCategory === 'footprint' && (
              <>
                 <Pane style={{ marginTop: "3px", cursor: "pointer", pointerEvents: "none" }} >
                 <Polyline 
                      pathOptions={limeOptions} 
                      positions={[[currentlySelectedShip.initialData.geometry.coordinates[1], currentlySelectedShip.initialData.geometry.coordinates[0]], [centerTimeline?.geometry?.coordinates[1], centerTimeline?.geometry?.coordinates[0]]]} 
                      color='green' dashArray={'20, 20'} dashOffset='20' weight={1}>
                 <Tooltip className='react-tooltip-custom' permanent={true}>Distance: 
                 {getDistance([currentlySelectedShip.initialData.geometry.coordinates[1], 
                  currentlySelectedShip.initialData.geometry.coordinates[0]], 
                  [centerTimeline?.geometry?.coordinates[1], centerTimeline?.geometry?.coordinates[0]])}
                  miles</Tooltip>
                 </Polyline>
                 </Pane>
              </>
            )}
            </>
          )
        }
        {satelliteData.geojson !== null && selectedShips.length > 0 && (
          <>
            {spoofingHeatmapVisible && <GeoJSON data={satelliteData.geojson} style={getStyle} key={satelliteData.key} />}
            {spoofingHeatmapVisible && currentlySelectedShip.shipCategory === 'footprint' && (
              <>
                 <Polyline 
                      pathOptions={limeOptions} 
                      positions={[[currentlySelectedShip.initialData.geometry.coordinates[1], currentlySelectedShip.initialData.geometry.coordinates[0]], getPolygonCenter(currentlySelectedShip)]} 
                      color='red' dashArray={'20, 20'} dashOffset='20' weight={1}>
                 <Tooltip className='react-custom-tooltip' permanent={true}>Distance: 
                 {getDistance([currentlySelectedShip.initialData.geometry.coordinates[1], 
                  currentlySelectedShip.initialData.geometry.coordinates[0]], 
                  getPolygonCenter(currentlySelectedShip))}
                  miles</Tooltip>
                 </Polyline>

              </>
            )}
            {spoofingHeatmapVisible && currentlySelectedShip.shipCategory === 'pink' && (
              <>
                <Polyline pathOptions={limeOptions} positions={[[currentlySelectedShip.initialData.geometry.coordinates[1], currentlySelectedShip.initialData.geometry.coordinates[0]], [center?.geometry?.coordinates[1], center?.geometry?.coordinates[0]]]} color='red' dashArray={'20, 20'} dashOffset='20' weight={1}>
                  <Tooltip permanent={true}>Distance: {getDistance([currentlySelectedShip.initialData.geometry.coordinates[1], currentlySelectedShip.initialData.geometry.coordinates[0]], [center?.geometry?.coordinates[1], center?.geometry?.coordinates[0]])} miles</Tooltip>
                </Polyline>
              
              </>
            )}
            {spoofingHeatmapVisible && currentlySelectedShip.shipCategory === 'ais-spoofing' && (
              <>
                <Polyline pathOptions={limeOptions} positions={[[currentlySelectedShip.initialData.geometry.coordinates[1], currentlySelectedShip.initialData.geometry.coordinates[0]], [center?.geometry?.coordinates[1], center?.geometry?.coordinates[0]]]} color='red' dashArray={'20, 20'} dashOffset='20' weight={1}>
                  <Tooltip permanent={true}>Distance: {getDistance([currentlySelectedShip.initialData.geometry.coordinates[1], currentlySelectedShip.initialData.geometry.coordinates[0]], [center?.geometry?.coordinates[1], center?.geometry?.coordinates[0]])} miles</Tooltip>
                </Polyline>

              </>
            )}
            
          </>
        )}

      </div>
    )
}

const getDistance = (pointA, pointB) => {
  var from = turf.point(pointB);
  var to = turf.point(pointA);
  var options = { units: 'miles' };

  return Math.round(turf.distance(from, to, options));
}

function heatMapColorforValue(value) {
    var h = (1.0 - value) * 240
    return "hsl(" + h + ", 100%, 50%)";
}

const getPolygonCenter = (shipData) => {
  console.log(shipData, "shipDatashipData")
  const polygonValue = shipData?.initialData?.properties?.geojson?.features?.filter(feature => feature.properties.confidence === 100)[0]
  let polygon;

  const polygonType = polygonValue.geometry.coordinates;
  const type = polygonValue.geometry.type;

  if (polygonType.length > 1) {
    if (Array.isArray(polygonValue.geometry.coordinates[0]) && type === "Polygon" ) {
      polygon = turf.points(polygonValue.geometry.coordinates[0])
    } else {
      polygon = turf.points(polygonValue.geometry.coordinates[0][0])
    }
  } else {
    polygon = turf.points(polygonValue.geometry.coordinates[0])
  }

  var center = turf.center(polygon);
  const centerValue =  center.geometry.coordinates;
  return [centerValue[1],centerValue[0]]

}

const getPolygonTimelineCenter = (shipData) => {
  const polygonValue = shipData?.initialData?.properties?.geojson?.features?.filter(feature => feature.properties.confidence === 100)[0]
  let polygon;

  const polygonType = polygonValue.geometry.coordinates;
  const type = polygonValue.geometry.type;

  if (polygonType.length > 1) {
    if (Array.isArray(polygonValue.geometry.coordinates[0]) && type === "Polygon" ) {
      polygon = turf.points(polygonValue.geometry.coordinates[0])
    } else {
      polygon = turf.points(polygonValue.geometry.coordinates[0][0])
    }
  } else {
    polygon = turf.points(polygonValue.geometry.coordinates[0])
  }

  var center = turf.center(polygon);
  const centerValue =  center.geometry.coordinates;
  return [centerValue[1],centerValue[0]]

}

export default SatelliteCoverageSpoofing