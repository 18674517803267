import React, { useEffect } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Tooltip,
    Button,
    TableSortLabel,
    Dialog,
    IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext } from 'react';
import { TheiaContext } from '../Theia';
import axios from 'axios';
import { frontendAPIURL, getShipDetails, getShipInfoUsingObjectId, getUniqueColor } from '../utils';
import { useState } from 'react';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DateRangeIcon from '@mui/icons-material/DateRange';
import XLSX from 'xlsx';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from "react-datepicker";
import { LoadingButton } from '@mui/lab';
import { useLayoutEffect } from 'react';

function AllShipsEvent() {
    const currentDate = moment();
    const end = currentDate.format('YYYY-MM-DD HH:mm:ss');

    const [portsOfCallsStartDate, setPortsOfCallsStartDate] = useState(new Date(moment(new Date()).subtract(30, 'days')));
    const [portsOfCallsEndDate, setPortsOfCallsEndDate] = useState(new Date());

    const { showSpinner, selectedShips, setSelectedShips, setShowSpinner, zIndex, setZindex, spoofingHeatmapVisible, setSpoofingHeatmapVisible, setHaveShipInfo, isLiveAisView, setIsSnackbarOpen, colorArr, setColorArr, setMapCenter, setSelectShipWaiting, setCurrentLocationData, setSpoofingHeatmapTimelineVisible } = useContext(TheiaContext);
    const [spoofingData, setSpoofingData] = useState([]);
    const [bunkeringData, setBunkeringData] = useState(null);
    const [aisBunkeringData, setAisBunkeringData] = useState(null);
    const [portsOfCallData, setPortsOfCallData] = useState([]);
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    const [isSecondAccordionExpanded, setIsSecondAccordionExpanded] = useState(false);
    const [isThirdAccordionExpanded, setIsThirdAccordionExpanded] = useState(false);
    const [portsOfCallDataDialog, setPortsOfCallDataDialog] = useState(false);
    const [isPathLoading, setIsPathLoading] = useState(false);

    useEffect(() => {
        setPortsOfCallDataDialog(false);
    }, [selectedShips])

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const [windowWidth, windowHeight] = useWindowSize();

    const [hasSpoofingDataLoaded, setHasSpoofingDataLoaded] = useState(false);
    const [hasBunkeringDataLoaded, setHasBunkeringDataLoaded] = useState(false);
    const [hasAisBunkeringDataLoaded, setAisHasBunkeringDataLoaded] = useState(false);
    const [heatMapArray, setHeatMapArray] = useState([]);
    const [orderBy, setOrderBy] = useState('Date');
    const [order, setOrder] = useState('asc');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [shouldRunEffect, setShouldRunEffect] = useState(true);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [portsOfCallDataDialogPosition, setPortsOfCallDataDialogPosition] = useState({
        clientX: null,
        clientY: null
    })

    useEffect(() => {
        setPortsOfCallDataDialogPosition({
            clientX: 0,
            clientY: 0
        });
    }, [windowWidth, windowHeight])

    const dragPortOfCallEventHandler = (e, data) => {
        setPortsOfCallDataDialogPosition({
            clientX: data.x,
            clientY: data.y
        })
    }

    let shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id
    if (!shipId) {
        shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.attribution
    }

    // footprint and optical spoofing data
    const showFootPrintSpoofingData = async () => {
        const token = localStorage.getItem('token')
        setShowSpinner(true)

        if (shipId === "None") {
            setShowSpinner(false)
            setSpoofingData([])
            return
        }

        try {
            const res = await axios.post(`${frontendAPIURL}/get_events`, {
                types: ["spoofing"], "start": "2022-01-01 00:00:00", "end": end, 'shipids': [shipId]
            }
                , {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })

            const newRes = await axios.post(`${frontendAPIURL}/get_events`, {
                types: ["footprint"], "start": "2022-01-01 00:00:00", "end": end, 'shipids': [shipId]
            }
                , {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })

            const updatedHeatMapArray = newRes?.data?.['footprint']?.map((item) => ({
                ...item,
                timestamp: item?.day / 1000
            }))


            const updatedArray = res?.data?.['spoofing']?.map(mainObj => {
                const secondObj = updatedHeatMapArray?.find(secondItem => secondItem.timestamp === mainObj.timestamp);
                return secondObj ? { ...mainObj, ...secondObj } : mainObj;
            });
            setSpoofingData(updatedArray);
            console.log(res?.data?.['spoofing'], spoofingData, "res")
        } catch (error) {
            console.log(error)
            setShowSpinner(false)
        }
        setHasSpoofingDataLoaded(false)
    }

    console.log(spoofingData, "spoofingData")

    const accordionExpand = () => {
        setIsAccordionExpanded(!isAccordionExpanded)
    }

    const accordionExpanded = () => {
        setIsSecondAccordionExpanded(!isSecondAccordionExpanded)
    }

    const thirdAccordionExpanded = () => {
        setIsThirdAccordionExpanded(!isThirdAccordionExpanded)
    }

    const updatedSpoofingArray = spoofingData?.map((item) => ({
        ...item,
        type: item.f ? "Footprint" : item.o ? "Optical" : item.a ? "Ais" : "",
        Date: moment(item?.timestamp * 1000).format('YYYY-MM-DD')
    }))

    const loadSearchedShip = async (row, type) => {

        const observationGeoJsonFeature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [row?.longitude, row?.latitude],
            },
            properties: {
                ...row,
            },
        }

        setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
        setMapCenter({ lat: row?.latitude, lng: row?.longitude, zoomLevel: 12 })
        getShipDetails(observationGeoJsonFeature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, type)
    }

    const handlePositionButtonClick = (row) => {
        console.log("row", row)
        setShowSpinner(true)
        setSpoofingHeatmapVisible(false)
        setSpoofingHeatmapTimelineVisible(false)
        const type = row?.type === "Footprint" ? "footprint" : row?.type === "Optical" ? "pink" : "ais-spoofing"
        loadSearchedShip(row, type)
    }

    useEffect(() => {
        if (!hasBunkeringDataLoaded) {
            showStsSpoofingData()
        }
    }, [])

    // bunkering data
    const showStsSpoofingData = async () => {
        const token = localStorage.getItem('token');
        setShowSpinner(true);

        if (shipId === "None") {
            setShowSpinner(false)
            setBunkeringData([])
            return
        }

        try {
            let payload = {
                types: ["optical_sts"],
                "start": "2022-01-01 00:00:00",
                "end": end,
            }

            const res = await axios.post(`${frontendAPIURL}/get_events`, payload, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            setBunkeringData(res?.data);
            // setShowSpinner(false)
        } catch (error) {
            setShowSpinner(false)
            console.log(error);
        } finally {
            // setShowSpinner(false);
            setHasBunkeringDataLoaded(true);
        }
    };

    // aisBunkeringData
    const showAisStsBunkeringData = async () => {
        const token = localStorage.getItem('token')
        setShowSpinner(true)

        if (shipId === "None") {
            setShowSpinner(false)
            setAisBunkeringData([])
            return
        }

        try {
            const res = await axios.post(`${frontendAPIURL}/get_events`, {
                types: ["ais_sts"], "start": "2022-01-01 00:00:00", "end": end, 'shipids': [shipId]
            }
                , {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })

            setAisBunkeringData(res?.data?.ais_sts)
            setShowSpinner(false)
        } catch (error) {
            console.log(error)
            setShowSpinner(false)
        }
        setAisHasBunkeringDataLoaded(false)
    }

    // ports of calls
    const showPortsOfCall = async () => {
        const token = localStorage.getItem('token')

        const startDate = moment.utc(`${moment(portsOfCallsStartDate).format('YYYY-MM-DD')} 00:00:00`).format('YYYY-MM-DD HH:mm:ss');
        const endDate = moment.utc(`${moment(portsOfCallsEndDate).format('YYYY-MM-DD')} 23:59:59`).format('YYYY-MM-DD HH:mm:ss');

        if (shipId === "None") {
            setShowSpinner(false)
            setPortsOfCallData([])
            return
        }

        try {
            setIsPathLoading(true)
            const res = await axios.post(`${frontendAPIURL}/get_events`, {
                types: ["ais_port_calls"], "start": startDate, "end": endDate, 'shipids': [shipId]
            }
                , {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })

            setIsPathLoading(false)
            setPortsOfCallData(res?.data?.["ais_port_calls"])
            setPortsOfCallDataDialog(false)

        } catch (error) {
            console.log(error)
            setShowSpinner(false)
            setIsPathLoading(false)
            setPortsOfCallDataDialog(false)
        }
    }

    const handleDateRange = (e) => {
        e.stopPropagation();
        setPortsOfCallDataDialog(true)
    }

    useEffect(() => {
        if (shouldRunEffect) {
            setIsAccordionExpanded(false)
            setIsSecondAccordionExpanded(false)
            setIsThirdAccordionExpanded(false)
            setHasSpoofingDataLoaded(true)
            setAisHasBunkeringDataLoaded(true)
            setPage(0);
            showFootPrintSpoofingData()
            showAisStsBunkeringData()
            showPortsOfCall()
        }
    }, [shipId])

    const bunkeringArray = bunkeringData?.["optical_sts"]?.filter((val) => {

        const ship1Attribution = val.ship1_attribution !== "None" && val.ship1_attribution
        const ship2Attribution = val.ship2_attribution !== "None" && val.ship2_attribution


        return (
            val.ship1_object_id === selectedShips[selectedShips?.length - 1]?.initialData?.properties?.object_id ||
            val.ship2_object_id === selectedShips[selectedShips?.length - 1]?.initialData?.properties?.object_id ||
            ship1Attribution === shipId ||
            ship2Attribution === shipId
        )
    });

    const veselName = selectedShips[selectedShips.length - 1]?.shipData?.static?.[0]?.name;
    const imo = selectedShips[selectedShips.length - 1]?.shipData?.static?.[0]?.imo;
    const final = `${veselName ? veselName : "No Info"}/ ${imo !== undefined ? imo : "No Info"}`;

    // Check if bunkeringArray is defined and an array before spreading
    const newBunkeringArray = Array.isArray(bunkeringArray) ? bunkeringArray?.map((item) => ({
        ...item,
        Type: "Optical_Bunkering",
        Date: moment(item?.acquired).format('YYYY-MM-DD')
    })) : [];

    const newAisBunkeringArray = Array.isArray(aisBunkeringData) ? aisBunkeringData?.map((item) => ({
        ...item,
        Type: "Ais_Bunkering",
        Date: moment(item?.timestamp_t0 * 1000).format('YYYY-MM-DD')
    })) : [];

    const updatedBunkeringArray = [...newBunkeringArray, ...newAisBunkeringArray]

    const updatedPortsOfCallArray = Array.isArray(portsOfCallData)
        ? portsOfCallData?.map((item) => {
            const durationInSeconds = item?.duration; 
            const duration = moment.duration(durationInSeconds, 'seconds');
            const hours = duration.hours();
            const minutes = duration.minutes();

            const updatedDuration = `${hours}h ${minutes}m`;

            return {
                ...item,
                Date: moment(item?.timestamp_t0 * 1000).format('YYYY-MM-DD'),
                Duration: updatedDuration 
            };
        })
        : [];

    const shipStatus = selectedShips[selectedShips?.length - 1]?.initialData?.isShipOne;

    const loadBunkeringShip = async (row, type) => {

        setShouldRunEffect(false);
        console.log(shouldRunEffect, "shouldRunEffect")

        const lat = shipStatus ? row?.ship1_lat : row?.ship2_lat;
        const lon = shipStatus ? row?.ship1_lon : row?.ship2_lon;
        const ship1Status = row?.ship1_dark ? "orange" : row?.ship1_dark === false ? "blue" : row?.ship1_dark === null ? "red" : "red";
        const ship2Status = row?.ship2_dark ? "orange" : row?.ship2_dark === false ? "blue" : row?.ship2_dark === null ? "red" : "red";
        if (type === "Optical_Bunkering") {

            const ship1Properties = {
                acquired: row?.acquired,
                attribution: row?.ship1_attribution,
                heading: row?.ship1_heading,
                latitude: row?.ship1_lat,
                longitude: row?.ship1_lon,
                length: row?.ship1_length,
                moving: row?.ship1_moving,
                object_id: row?.ship1_object_id,
                ship_type: row?.ship1_ship_type,
                source_id: row?.source_id,
                status: row?.ship1_status,
                bunkering: row?.ship1_bunkering,
                dark: row?.ship1_dark,
                dark_time: row?.ship1_dark_time,
                ship1Status,
                ship2Status,
                fromDecoupleModel: true,
                xPixel: row?.ship1_clip_pxl_x,
                yPixel: row?.ship1_clip_pxl_y
            }

            const ship2Properties = {
                acquired: row?.acquired,
                attribution: row?.ship2_attribution,
                heading: row?.ship2_heading,
                latitude: row?.ship2_lat,
                longitude: row?.ship2_lon,
                length: row?.ship2_length,
                moving: row?.ship2_moving,
                object_id: row?.ship2_object_id,
                ship_type: row?.ship2_ship_type,
                source_id: row?.source_id,
                status: row?.ship2_status,
                bunkering: row?.ship2_bunkering,
                dark: row?.ship2_dark,
                dark_time: row?.ship2_dark_time,
                ship1Status,
                ship2Status,
                fromDecoupleModel: true,
                xPixel: row?.ship2_clip_pxl_x,
                yPixel: row?.ship2_clip_pxl_y
            }

            const shipFeature = {
                geometry: {
                    type: 'Point',
                    coordinates: [lon, lat]
                },
                properties: ship1Properties,
                type: "Feature",
                id: parseInt(Math.random() * 10000),
                ship2Properties,
                ship1Properties,
                isShipOne: true
            }
            if (ship1Status === 'blue' || ship1Status === 'orange') {
                setMapCenter({ lat: lat, lng: lon, zoomLevel: 12 })
                setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
                getShipDetails(shipFeature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, ship1Status)
            } else {
                let shipIndex = 1;
                if (selectedShips.length > 0) {
                    let highestValue = 0
                    selectedShips.forEach(ship => {
                        if (ship.shipIndex > highestValue) {
                            highestValue = ship.shipIndex
                        }
                    })
                    shipIndex = highestValue + 1
                }
                console.log('redbunkering:', { shipCategory: 'red', initialData: { ...shipFeature, isClicked: true }, shipIndex, visible: true, pathVisible: true, shipData: { ...shipFeature.properties, name: 'Dark Ship' }, showCircle: true, path: [], color: getUniqueColor(colorArr, setColorArr) })
                setSelectedShips([...selectedShips, { shipCategory: 'red', initialData: { ...shipFeature, isClicked: true }, shipIndex, visible: true, pathVisible: true, shipData: { ...shipFeature.properties, name: 'Dark Ship' }, showCircle: true, path: [], color: getUniqueColor(colorArr, setColorArr) }])
                setSelectShipWaiting(false)
            }
        } else {
            const lat = row?.latitude;
            const lon = row?.longitude;
            const ship1Properties = {
                acquired: row?.timestamp_t0 * 1000,
                synmax_ship_id: row?.synmax_ship_id_1,
                latitude: row?.latitude,
                longitude: row?.longitude,
                ship1Status: "ais",
                ship2Status: "ais",
                status: row?.status,
                bunkering: "ais_bunkering",
                fromDecoupleModel: true
            }

            const ship2Properties = {
                acquired: (row?.timestamp_t1 ? row?.timestamp_t1 : row?.timestamp_t0) * 1000,
                synmax_ship_id: row?.synmax_ship_id_2,
                latitude: row?.latitude,
                longitude: row?.longitude,
                ship1Status: "ais",
                ship2Status: "ais",
                status: row?.status,
                bunkering: "ais_bunkering",
                fromDecoupleModel: true
            }

            const shipFeature = {
                geometry: {
                    type: 'Point',
                    coordinates: [lon, lat]
                },
                properties: ship1Properties,
                type: "Feature",
                id: parseInt(Math.random() * 10000),
                ship2Properties,
                ship1Properties,
                isShipOne: true
            }
            await getShipDetails(shipFeature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, 'ais')
            setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
        }
        setTimeout(() => {
            setShouldRunEffect(true);
        }, 0);
    }

    const handleBunkeringButtonClick = (row) => {
        const type = row?.Type;
        loadBunkeringShip(row, type)
    }

    const handleDownloadXls = (e, type) => {
        e.stopPropagation();
        if (type === "bunkering") {
            const ws = XLSX.utils.json_to_sheet(updatedBunkeringArray);
            const wb = XLSX.utils.book_new();
            if (updatedBunkeringArray) {
                XLSX.utils.book_append_sheet(wb, ws, "fileName");
            }
            XLSX.writeFile(wb, "BunkeringData" + '.xlsx');
        } else if (type === "portsOfCalls") {
            const ws = XLSX.utils.json_to_sheet(portsOfCallData);
            const wb = XLSX.utils.book_new();
            if (updatedBunkeringArray) {
                XLSX.utils.book_append_sheet(wb, ws, "fileName");
            }
            XLSX.writeFile(wb, "PortsOfCallData" + '.xlsx');
        }
        else {
            const ws = XLSX.utils.json_to_sheet(updatedSpoofingArray);
            const wb = XLSX.utils.book_new();
            if (updatedSpoofingArray) {
                XLSX.utils.book_append_sheet(wb, ws, "fileName");
            }
            XLSX.writeFile(wb, "SpoofingData" + '.xlsx');
        }
    }

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedArray = updatedSpoofingArray.slice().sort((a, b) => {
        const dateA = new Date(a[orderBy]);
        const dateB = new Date(b[orderBy]);

        if (order === "desc") {
            return dateA - dateB;
        } else {
            return dateB - dateA;
        }
    });

    const sortedBunkeringArray = updatedBunkeringArray.slice().sort((a, b) => {
        const dateA = new Date(a[orderBy]);
        const dateB = new Date(b[orderBy]);

        if (order === "desc") {
            return dateA - dateB;
        } else {
            return dateB - dateA;
        }
    });

    const sortedPortsOfCallsArray = updatedPortsOfCallArray.slice().sort((a, b) => {
        const dateA = new Date(a[orderBy]);
        const dateB = new Date(b[orderBy]);

        if (order === "desc") {
            return dateA - dateB;
        } else {
            return dateB - dateA;
        }
    });

    return (
        <div className='allships_events'>
            <Accordion expanded={isAccordionExpanded} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    onClick={accordionExpand}
                >
                    <Typography>Spoofing (Total: {updatedSpoofingArray?.length ? updatedSpoofingArray?.length : 0} Events ) </Typography>
                    <Tooltip title="Download CSV" >
                        <div className='download_icon'>
                            <CloudDownloadIcon
                                title="test"
                                onClick={(e) => handleDownloadXls(e, "spoofing")}
                            />
                        </div>
                    </Tooltip>
                </AccordionSummary>
                <AccordionDetails>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead className='accordion-summary' >
                                <TableRow >
                                    <TableCell >ID</TableCell>
                                    <TableCell >Type</TableCell>
                                    <TableCell >Latitude</TableCell>
                                    <TableCell >Longitude</TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'Date'}
                                        direction={orderBy === 'Date' ? order : 'asc'}
                                        onClick={() => handleSortRequest('Date')}
                                    >
                                        <TableCell >Date</TableCell>
                                    </TableSortLabel>
                                    <TableCell >Position</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    sortedArray?.length > 0 ?
                                        (sortedArray?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            ?.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                                    <TableCell>{row.type}</TableCell>
                                                    <TableCell>{row?.latitude}</TableCell>
                                                    <TableCell>{row?.longitude}</TableCell>
                                                    <TableCell>{row?.Date}</TableCell>
                                                    <TableCell>
                                                        <Button variant='outlined' onClick={() => handlePositionButtonClick(row)} >
                                                            View Event
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))) : (
                                            <TableRow >
                                                <TableCell colSpan={6} style={{ textAlign: 'center', verticalAlign: 'middle' }} >No Data Found!</TableCell>
                                            </TableRow>
                                        )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={sortedArray?.length ? sortedArray?.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </AccordionDetails>
            </Accordion>

            <Accordion expanded={isSecondAccordionExpanded} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    onClick={accordionExpanded}
                >
                    <Typography>Bunkering / STS (Total: {updatedBunkeringArray?.length ? updatedBunkeringArray?.length : 0} Event )</Typography>
                    <Tooltip title="Download CSV" >
                        <div className='download_icon'>
                            <CloudDownloadIcon
                                title="test"
                                onClick={(e) => handleDownloadXls(e, "bunkering")}
                            />
                        </div>
                    </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead className='accordion-summary'>
                                <TableRow >
                                    <TableCell > ID</TableCell>
                                    <TableCell > Type</TableCell>
                                    <TableCell > Event ID  </TableCell>
                                    <TableCell > {shipStatus ? "Ship1 Attribution" : "Ship2 Attribution"} </TableCell>
                                    <TableCell > {shipStatus ? "Ship1 ObjectId" : "Ship2 ObjectId"} </TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'Date'}
                                        direction={orderBy === 'Date' ? order : 'asc'}
                                        onClick={() => handleSortRequest('Date')}
                                    >
                                        <TableCell >Date</TableCell>
                                    </TableSortLabel>
                                    <TableCell > Position</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedBunkeringArray?.length > 0 ? (
                                    sortedBunkeringArray?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (

                                        <TableRow key={row.id}>
                                            <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                            <TableCell>{row?.Type === "Ais_Bunkering" ? "AIS Detection" : "Optical Detection"}</TableCell>
                                            <TableCell>{row?.Type === "Ais_Bunkering" ? "-" : row?.bunkering_id}</TableCell>
                                            {/* <TableCell>{shipStatus ? (row?.synmax_ship_id_1 ? row?.synmax_ship_id_1 : row?.ship1_attribution) : (row?.synmax_ship_id_2 ? row?.synmax_ship_id_2 : row?.ship2_attribution) }</TableCell> */}
                                            {/* <TableCell>{shipStatus ? (row?.synmax_ship_id_1 ? row?.ShipName + " / " + row?.ShipImo : bunkeringShipName ? bunkeringShipName + " / " + bunkeringShipImo : "No Info") : (row?.synmax_ship_id_2 ? row?.ShipName + " / " + row?.ShipImo : bunkeringShipName ? bunkeringShipName + " / " + bunkeringShipImo : "No Info")}</TableCell> */}
                                            <TableCell> {final} </TableCell>
                                            <TableCell>{row?.Type === "Ais_Bunkering" ? "-" : (shipStatus ? row?.ship1_object_id : row?.ship2_object_id)}</TableCell>
                                            <TableCell>{row?.Date}</TableCell>
                                            <TableCell>
                                                <Button variant='outlined' onClick={() => handleBunkeringButtonClick(row)} >
                                                    View Event
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} style={{ textAlign: 'center', verticalAlign: 'middle' }} >No Data Found!</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={sortedBunkeringArray?.length ? sortedBunkeringArray?.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </AccordionDetails>
            </Accordion>

            <Accordion expanded={isThirdAccordionExpanded} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    onClick={thirdAccordionExpanded}
                >
                    <Typography>Port of Calls (Total: {sortedPortsOfCallsArray?.length ? sortedPortsOfCallsArray?.length : 0} Ports )</Typography>
                    <div className='download_icon'>
                        <Tooltip title="Date Range" >
                            <DateRangeIcon style={{ marginRight: "10px" }} onClick={(e) => handleDateRange(e)} />
                        </Tooltip>
                        <Tooltip title="Download CSV" >
                            <CloudDownloadIcon
                                onClick={(e) => handleDownloadXls(e, "portsOfCalls")}
                            />
                        </Tooltip>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead className='accordion-summary'>
                                <TableRow >
                                    <TableCell> ID</TableCell>
                                    <TableCell> Port Name</TableCell>
                                    <TableCell> MMSI</TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'Date'}
                                        direction={orderBy === 'Date' ? order : 'asc'}
                                        onClick={() => handleSortRequest('Date')}
                                    >
                                        <TableCell >Date</TableCell>
                                    </TableSortLabel>
                                    <TableCell> Status</TableCell>
                                    <TableCell> Duration</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    sortedPortsOfCallsArray?.length > 0 ? (
                                        sortedPortsOfCallsArray?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                            <TableRow>
                                                <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                                <TableCell>{row.port_name}</TableCell>
                                                <TableCell>{row.mmsi}</TableCell>
                                                <TableCell>{row.Date}</TableCell>
                                                <TableCell>{row.status}</TableCell>
                                                <TableCell>{row.Duration}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} style={{ textAlign: 'center', verticalAlign: 'middle' }} >No Data Found!</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={sortedPortsOfCallsArray?.length ? sortedPortsOfCallsArray?.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </AccordionDetails>
            </Accordion>

            <Draggable
                axis="both"
                handle=".extendedPathDraggable"
                defaultPosition={{ x: 0, y: 0 }}
                position={{ x: portsOfCallDataDialogPosition.clientX, y: portsOfCallDataDialogPosition.clientY }}
                bounds="body"
                onDrag={dragPortOfCallEventHandler}
                onStart={() => setZindex({
                    dropPin: false,
                    pathSlider: false,
                    attribution: false,
                    satelliteImage: false,
                    extendedPath: false,
                    polygonSlider: false,
                    polygonDataTable: false,
                    futurePathModal: false,
                    futurePathSlider: false,
                    spoofingTimelineSlider: false,
                    portsOfCallSlider: true,
                })}
            >
                <Dialog
                    open={portsOfCallDataDialog}
                    onClose={() => setPortsOfCallDataDialog(false)}
                    style={{ right: 'auto' }}
                    className={`${zIndex.portsOfCallSlider ? 'zindex-true' : 'zindex-false'} extendedPathDraggable`}
                >

                    <div className="extendedPath">
                        <div className="extendedPath__heading">
                            Pick a date for ports of calls:
                        </div>
                        <IconButton onClick={() => setPortsOfCallDataDialog(false)} style={{ position: 'absolute', right: 2, top: 8 }} >
                            <CloseIcon />
                        </IconButton>
                        <div className="extendedPath__content">
                            <div className="extendedPath__dates">
                                <div className="extendedPath__date extendedPath__date--1">
                                    <div className="extendedPath__date--text">Select Date</div>
                                    <DatePicker
                                        selected={portsOfCallsStartDate}
                                        onChange={(date) => {
                                            setPortsOfCallsStartDate(date);
                                        }}
                                    />
                                </div> 
                                <div className="extendedPath__date">
                                    <div className="extendedPath__date--text">End Date</div>
                                    <DatePicker
                                        selected={portsOfCallsEndDate}
                                        onChange={(date) => {
                                            setPortsOfCallsEndDate(date);
                                        }}
                                    /> 
                                </div>
                            </div>

                            <LoadingButton
                                size="small"
                                color='success'
                                variant="contained"
                                className="button"
                                onClick={showPortsOfCall}
                                loading={isPathLoading}
                                style={{ marginTop: 15 }}
                            >
                                {"Get Ports Of Calls"}
                            </LoadingButton>
                        </div>
                    </div>
                </Dialog>
            </Draggable>

        </div >
    );
}

export default AllShipsEvent;
